/** @jsxImportSource minimal-view */

import { render, enableDebug, effect } from 'minimal-view'

effect.maxUpdates = 100000

import { AppView } from 'cowbell-lol'

setTimeout(() => {
  render(
    <AppView distRoot="" apiUrl="https://projects.cowbell.workers.dev" />
    , document.body)
})

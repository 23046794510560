import { Func } from './types/Func'
import { UnaryFunction } from './types/UnaryFunction'
import { pipeImpl } from './pipeImpl'

/**
 * Type-enforcing right-to-left function composition function.
 * The last parameter (i.e. the first function to be evaluated) in the argument list can be a function of any arity, but the remaining parameters must be unary functions.
 * The return type of one function must be compatible with the argument of previous function in the argument list
 * (i.e. types flow from right-to-left)
 * @returns A function with the arguments of the last function in the argument list and a return type of the first function in the argument list
 */
export function compose<TIn extends any[], TOut>(f0: Func<TIn, TOut>): Func<TIn, TOut>
export function compose<TIn extends any[], T1, TOut>(
  f0: UnaryFunction<T1, TOut>,
  f1: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<TIn extends any[], T1, T2, TOut>(
  f0: UnaryFunction<T2, TOut>,
  f1: UnaryFunction<T1, T2>,
  f2: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<TIn extends any[], T1, T2, T3, TOut>(
  f0: UnaryFunction<T3, TOut>,
  f1: UnaryFunction<T2, T3>,
  f2: UnaryFunction<T1, T2>,
  f3: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<TIn extends any[], T1, T2, T3, T4, TOut>(
  f0: UnaryFunction<T4, TOut>,
  f1: UnaryFunction<T3, T4>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T1, T2>,
  f4: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<TIn extends any[], T1, T2, T3, T4, T5, TOut>(
  f0: UnaryFunction<T5, TOut>,
  f1: UnaryFunction<T4, T5>,
  f2: UnaryFunction<T3, T4>,
  f3: UnaryFunction<T2, T3>,
  f4: UnaryFunction<T1, T2>,
  f5: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<TIn extends any[], T1, T2, T3, T4, T5, T6, TOut>(
  f0: UnaryFunction<T6, TOut>,
  f1: UnaryFunction<T5, T6>,
  f2: UnaryFunction<T4, T5>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T2, T3>,
  f5: UnaryFunction<T1, T2>,
  f6: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<TIn extends any[], T1, T2, T3, T4, T5, T6, T7, TOut>(
  f0: UnaryFunction<T7, TOut>,
  f1: UnaryFunction<T6, T7>,
  f2: UnaryFunction<T5, T6>,
  f3: UnaryFunction<T4, T5>,
  f4: UnaryFunction<T3, T4>,
  f5: UnaryFunction<T2, T3>,
  f6: UnaryFunction<T1, T2>,
  f7: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<TIn extends any[], T1, T2, T3, T4, T5, T6, T7, T8, TOut>(
  f0: UnaryFunction<T8, TOut>,
  f1: UnaryFunction<T7, T8>,
  f2: UnaryFunction<T6, T7>,
  f3: UnaryFunction<T5, T6>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T3, T4>,
  f6: UnaryFunction<T2, T3>,
  f7: UnaryFunction<T1, T2>,
  f8: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<TIn extends any[], T1, T2, T3, T4, T5, T6, T7, T8, T9, TOut>(
  f0: UnaryFunction<T9, TOut>,
  f1: UnaryFunction<T8, T9>,
  f2: UnaryFunction<T7, T8>,
  f3: UnaryFunction<T6, T7>,
  f4: UnaryFunction<T5, T6>,
  f5: UnaryFunction<T4, T5>,
  f6: UnaryFunction<T3, T4>,
  f7: UnaryFunction<T2, T3>,
  f8: UnaryFunction<T1, T2>,
  f9: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<TIn extends any[], T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, TOut>(
  f0: UnaryFunction<T10, TOut>,
  f1: UnaryFunction<T9, T10>,
  f2: UnaryFunction<T8, T9>,
  f3: UnaryFunction<T7, T8>,
  f4: UnaryFunction<T6, T7>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T4, T5>,
  f7: UnaryFunction<T3, T4>,
  f8: UnaryFunction<T2, T3>,
  f9: UnaryFunction<T1, T2>,
  f10: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<TIn extends any[], T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, T11, TOut>(
  f0: UnaryFunction<T11, TOut>,
  f1: UnaryFunction<T10, T11>,
  f2: UnaryFunction<T9, T10>,
  f3: UnaryFunction<T8, T9>,
  f4: UnaryFunction<T7, T8>,
  f5: UnaryFunction<T6, T7>,
  f6: UnaryFunction<T5, T6>,
  f7: UnaryFunction<T4, T5>,
  f8: UnaryFunction<T3, T4>,
  f9: UnaryFunction<T2, T3>,
  f10: UnaryFunction<T1, T2>,
  f11: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<TIn extends any[], T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, T11, T12, TOut>(
  f0: UnaryFunction<T12, TOut>,
  f1: UnaryFunction<T11, T12>,
  f2: UnaryFunction<T10, T11>,
  f3: UnaryFunction<T9, T10>,
  f4: UnaryFunction<T8, T9>,
  f5: UnaryFunction<T7, T8>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T5, T6>,
  f8: UnaryFunction<T4, T5>,
  f9: UnaryFunction<T3, T4>,
  f10: UnaryFunction<T2, T3>,
  f11: UnaryFunction<T1, T2>,
  f12: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  TOut
>(
  f0: UnaryFunction<T13, TOut>,
  f1: UnaryFunction<T12, T13>,
  f2: UnaryFunction<T11, T12>,
  f3: UnaryFunction<T10, T11>,
  f4: UnaryFunction<T9, T10>,
  f5: UnaryFunction<T8, T9>,
  f6: UnaryFunction<T7, T8>,
  f7: UnaryFunction<T6, T7>,
  f8: UnaryFunction<T5, T6>,
  f9: UnaryFunction<T4, T5>,
  f10: UnaryFunction<T3, T4>,
  f11: UnaryFunction<T2, T3>,
  f12: UnaryFunction<T1, T2>,
  f13: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  TOut
>(
  f0: UnaryFunction<T14, TOut>,
  f1: UnaryFunction<T13, T14>,
  f2: UnaryFunction<T12, T13>,
  f3: UnaryFunction<T11, T12>,
  f4: UnaryFunction<T10, T11>,
  f5: UnaryFunction<T9, T10>,
  f6: UnaryFunction<T8, T9>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T6, T7>,
  f9: UnaryFunction<T5, T6>,
  f10: UnaryFunction<T4, T5>,
  f11: UnaryFunction<T3, T4>,
  f12: UnaryFunction<T2, T3>,
  f13: UnaryFunction<T1, T2>,
  f14: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  TOut
>(
  f0: UnaryFunction<T15, TOut>,
  f1: UnaryFunction<T14, T15>,
  f2: UnaryFunction<T13, T14>,
  f3: UnaryFunction<T12, T13>,
  f4: UnaryFunction<T11, T12>,
  f5: UnaryFunction<T10, T11>,
  f6: UnaryFunction<T9, T10>,
  f7: UnaryFunction<T8, T9>,
  f8: UnaryFunction<T7, T8>,
  f9: UnaryFunction<T6, T7>,
  f10: UnaryFunction<T5, T6>,
  f11: UnaryFunction<T4, T5>,
  f12: UnaryFunction<T3, T4>,
  f13: UnaryFunction<T2, T3>,
  f14: UnaryFunction<T1, T2>,
  f15: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  TOut
>(
  f0: UnaryFunction<T16, TOut>,
  f1: UnaryFunction<T15, T16>,
  f2: UnaryFunction<T14, T15>,
  f3: UnaryFunction<T13, T14>,
  f4: UnaryFunction<T12, T13>,
  f5: UnaryFunction<T11, T12>,
  f6: UnaryFunction<T10, T11>,
  f7: UnaryFunction<T9, T10>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T7, T8>,
  f10: UnaryFunction<T6, T7>,
  f11: UnaryFunction<T5, T6>,
  f12: UnaryFunction<T4, T5>,
  f13: UnaryFunction<T3, T4>,
  f14: UnaryFunction<T2, T3>,
  f15: UnaryFunction<T1, T2>,
  f16: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  TOut
>(
  f0: UnaryFunction<T17, TOut>,
  f1: UnaryFunction<T16, T17>,
  f2: UnaryFunction<T15, T16>,
  f3: UnaryFunction<T14, T15>,
  f4: UnaryFunction<T13, T14>,
  f5: UnaryFunction<T12, T13>,
  f6: UnaryFunction<T11, T12>,
  f7: UnaryFunction<T10, T11>,
  f8: UnaryFunction<T9, T10>,
  f9: UnaryFunction<T8, T9>,
  f10: UnaryFunction<T7, T8>,
  f11: UnaryFunction<T6, T7>,
  f12: UnaryFunction<T5, T6>,
  f13: UnaryFunction<T4, T5>,
  f14: UnaryFunction<T3, T4>,
  f15: UnaryFunction<T2, T3>,
  f16: UnaryFunction<T1, T2>,
  f17: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  TOut
>(
  f0: UnaryFunction<T18, TOut>,
  f1: UnaryFunction<T17, T18>,
  f2: UnaryFunction<T16, T17>,
  f3: UnaryFunction<T15, T16>,
  f4: UnaryFunction<T14, T15>,
  f5: UnaryFunction<T13, T14>,
  f6: UnaryFunction<T12, T13>,
  f7: UnaryFunction<T11, T12>,
  f8: UnaryFunction<T10, T11>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T8, T9>,
  f11: UnaryFunction<T7, T8>,
  f12: UnaryFunction<T6, T7>,
  f13: UnaryFunction<T5, T6>,
  f14: UnaryFunction<T4, T5>,
  f15: UnaryFunction<T3, T4>,
  f16: UnaryFunction<T2, T3>,
  f17: UnaryFunction<T1, T2>,
  f18: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  TOut
>(
  f0: UnaryFunction<T19, TOut>,
  f1: UnaryFunction<T18, T19>,
  f2: UnaryFunction<T17, T18>,
  f3: UnaryFunction<T16, T17>,
  f4: UnaryFunction<T15, T16>,
  f5: UnaryFunction<T14, T15>,
  f6: UnaryFunction<T13, T14>,
  f7: UnaryFunction<T12, T13>,
  f8: UnaryFunction<T11, T12>,
  f9: UnaryFunction<T10, T11>,
  f10: UnaryFunction<T9, T10>,
  f11: UnaryFunction<T8, T9>,
  f12: UnaryFunction<T7, T8>,
  f13: UnaryFunction<T6, T7>,
  f14: UnaryFunction<T5, T6>,
  f15: UnaryFunction<T4, T5>,
  f16: UnaryFunction<T3, T4>,
  f17: UnaryFunction<T2, T3>,
  f18: UnaryFunction<T1, T2>,
  f19: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  TOut
>(
  f0: UnaryFunction<T20, TOut>,
  f1: UnaryFunction<T19, T20>,
  f2: UnaryFunction<T18, T19>,
  f3: UnaryFunction<T17, T18>,
  f4: UnaryFunction<T16, T17>,
  f5: UnaryFunction<T15, T16>,
  f6: UnaryFunction<T14, T15>,
  f7: UnaryFunction<T13, T14>,
  f8: UnaryFunction<T12, T13>,
  f9: UnaryFunction<T11, T12>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T9, T10>,
  f12: UnaryFunction<T8, T9>,
  f13: UnaryFunction<T7, T8>,
  f14: UnaryFunction<T6, T7>,
  f15: UnaryFunction<T5, T6>,
  f16: UnaryFunction<T4, T5>,
  f17: UnaryFunction<T3, T4>,
  f18: UnaryFunction<T2, T3>,
  f19: UnaryFunction<T1, T2>,
  f20: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  TOut
>(
  f0: UnaryFunction<T21, TOut>,
  f1: UnaryFunction<T20, T21>,
  f2: UnaryFunction<T19, T20>,
  f3: UnaryFunction<T18, T19>,
  f4: UnaryFunction<T17, T18>,
  f5: UnaryFunction<T16, T17>,
  f6: UnaryFunction<T15, T16>,
  f7: UnaryFunction<T14, T15>,
  f8: UnaryFunction<T13, T14>,
  f9: UnaryFunction<T12, T13>,
  f10: UnaryFunction<T11, T12>,
  f11: UnaryFunction<T10, T11>,
  f12: UnaryFunction<T9, T10>,
  f13: UnaryFunction<T8, T9>,
  f14: UnaryFunction<T7, T8>,
  f15: UnaryFunction<T6, T7>,
  f16: UnaryFunction<T5, T6>,
  f17: UnaryFunction<T4, T5>,
  f18: UnaryFunction<T3, T4>,
  f19: UnaryFunction<T2, T3>,
  f20: UnaryFunction<T1, T2>,
  f21: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  TOut
>(
  f0: UnaryFunction<T22, TOut>,
  f1: UnaryFunction<T21, T22>,
  f2: UnaryFunction<T20, T21>,
  f3: UnaryFunction<T19, T20>,
  f4: UnaryFunction<T18, T19>,
  f5: UnaryFunction<T17, T18>,
  f6: UnaryFunction<T16, T17>,
  f7: UnaryFunction<T15, T16>,
  f8: UnaryFunction<T14, T15>,
  f9: UnaryFunction<T13, T14>,
  f10: UnaryFunction<T12, T13>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T10, T11>,
  f13: UnaryFunction<T9, T10>,
  f14: UnaryFunction<T8, T9>,
  f15: UnaryFunction<T7, T8>,
  f16: UnaryFunction<T6, T7>,
  f17: UnaryFunction<T5, T6>,
  f18: UnaryFunction<T4, T5>,
  f19: UnaryFunction<T3, T4>,
  f20: UnaryFunction<T2, T3>,
  f21: UnaryFunction<T1, T2>,
  f22: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  TOut
>(
  f0: UnaryFunction<T23, TOut>,
  f1: UnaryFunction<T22, T23>,
  f2: UnaryFunction<T21, T22>,
  f3: UnaryFunction<T20, T21>,
  f4: UnaryFunction<T19, T20>,
  f5: UnaryFunction<T18, T19>,
  f6: UnaryFunction<T17, T18>,
  f7: UnaryFunction<T16, T17>,
  f8: UnaryFunction<T15, T16>,
  f9: UnaryFunction<T14, T15>,
  f10: UnaryFunction<T13, T14>,
  f11: UnaryFunction<T12, T13>,
  f12: UnaryFunction<T11, T12>,
  f13: UnaryFunction<T10, T11>,
  f14: UnaryFunction<T9, T10>,
  f15: UnaryFunction<T8, T9>,
  f16: UnaryFunction<T7, T8>,
  f17: UnaryFunction<T6, T7>,
  f18: UnaryFunction<T5, T6>,
  f19: UnaryFunction<T4, T5>,
  f20: UnaryFunction<T3, T4>,
  f21: UnaryFunction<T2, T3>,
  f22: UnaryFunction<T1, T2>,
  f23: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  TOut
>(
  f0: UnaryFunction<T24, TOut>,
  f1: UnaryFunction<T23, T24>,
  f2: UnaryFunction<T22, T23>,
  f3: UnaryFunction<T21, T22>,
  f4: UnaryFunction<T20, T21>,
  f5: UnaryFunction<T19, T20>,
  f6: UnaryFunction<T18, T19>,
  f7: UnaryFunction<T17, T18>,
  f8: UnaryFunction<T16, T17>,
  f9: UnaryFunction<T15, T16>,
  f10: UnaryFunction<T14, T15>,
  f11: UnaryFunction<T13, T14>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T11, T12>,
  f14: UnaryFunction<T10, T11>,
  f15: UnaryFunction<T9, T10>,
  f16: UnaryFunction<T8, T9>,
  f17: UnaryFunction<T7, T8>,
  f18: UnaryFunction<T6, T7>,
  f19: UnaryFunction<T5, T6>,
  f20: UnaryFunction<T4, T5>,
  f21: UnaryFunction<T3, T4>,
  f22: UnaryFunction<T2, T3>,
  f23: UnaryFunction<T1, T2>,
  f24: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  TOut
>(
  f0: UnaryFunction<T25, TOut>,
  f1: UnaryFunction<T24, T25>,
  f2: UnaryFunction<T23, T24>,
  f3: UnaryFunction<T22, T23>,
  f4: UnaryFunction<T21, T22>,
  f5: UnaryFunction<T20, T21>,
  f6: UnaryFunction<T19, T20>,
  f7: UnaryFunction<T18, T19>,
  f8: UnaryFunction<T17, T18>,
  f9: UnaryFunction<T16, T17>,
  f10: UnaryFunction<T15, T16>,
  f11: UnaryFunction<T14, T15>,
  f12: UnaryFunction<T13, T14>,
  f13: UnaryFunction<T12, T13>,
  f14: UnaryFunction<T11, T12>,
  f15: UnaryFunction<T10, T11>,
  f16: UnaryFunction<T9, T10>,
  f17: UnaryFunction<T8, T9>,
  f18: UnaryFunction<T7, T8>,
  f19: UnaryFunction<T6, T7>,
  f20: UnaryFunction<T5, T6>,
  f21: UnaryFunction<T4, T5>,
  f22: UnaryFunction<T3, T4>,
  f23: UnaryFunction<T2, T3>,
  f24: UnaryFunction<T1, T2>,
  f25: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  TOut
>(
  f0: UnaryFunction<T26, TOut>,
  f1: UnaryFunction<T25, T26>,
  f2: UnaryFunction<T24, T25>,
  f3: UnaryFunction<T23, T24>,
  f4: UnaryFunction<T22, T23>,
  f5: UnaryFunction<T21, T22>,
  f6: UnaryFunction<T20, T21>,
  f7: UnaryFunction<T19, T20>,
  f8: UnaryFunction<T18, T19>,
  f9: UnaryFunction<T17, T18>,
  f10: UnaryFunction<T16, T17>,
  f11: UnaryFunction<T15, T16>,
  f12: UnaryFunction<T14, T15>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T12, T13>,
  f15: UnaryFunction<T11, T12>,
  f16: UnaryFunction<T10, T11>,
  f17: UnaryFunction<T9, T10>,
  f18: UnaryFunction<T8, T9>,
  f19: UnaryFunction<T7, T8>,
  f20: UnaryFunction<T6, T7>,
  f21: UnaryFunction<T5, T6>,
  f22: UnaryFunction<T4, T5>,
  f23: UnaryFunction<T3, T4>,
  f24: UnaryFunction<T2, T3>,
  f25: UnaryFunction<T1, T2>,
  f26: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  TOut
>(
  f0: UnaryFunction<T27, TOut>,
  f1: UnaryFunction<T26, T27>,
  f2: UnaryFunction<T25, T26>,
  f3: UnaryFunction<T24, T25>,
  f4: UnaryFunction<T23, T24>,
  f5: UnaryFunction<T22, T23>,
  f6: UnaryFunction<T21, T22>,
  f7: UnaryFunction<T20, T21>,
  f8: UnaryFunction<T19, T20>,
  f9: UnaryFunction<T18, T19>,
  f10: UnaryFunction<T17, T18>,
  f11: UnaryFunction<T16, T17>,
  f12: UnaryFunction<T15, T16>,
  f13: UnaryFunction<T14, T15>,
  f14: UnaryFunction<T13, T14>,
  f15: UnaryFunction<T12, T13>,
  f16: UnaryFunction<T11, T12>,
  f17: UnaryFunction<T10, T11>,
  f18: UnaryFunction<T9, T10>,
  f19: UnaryFunction<T8, T9>,
  f20: UnaryFunction<T7, T8>,
  f21: UnaryFunction<T6, T7>,
  f22: UnaryFunction<T5, T6>,
  f23: UnaryFunction<T4, T5>,
  f24: UnaryFunction<T3, T4>,
  f25: UnaryFunction<T2, T3>,
  f26: UnaryFunction<T1, T2>,
  f27: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  TOut
>(
  f0: UnaryFunction<T28, TOut>,
  f1: UnaryFunction<T27, T28>,
  f2: UnaryFunction<T26, T27>,
  f3: UnaryFunction<T25, T26>,
  f4: UnaryFunction<T24, T25>,
  f5: UnaryFunction<T23, T24>,
  f6: UnaryFunction<T22, T23>,
  f7: UnaryFunction<T21, T22>,
  f8: UnaryFunction<T20, T21>,
  f9: UnaryFunction<T19, T20>,
  f10: UnaryFunction<T18, T19>,
  f11: UnaryFunction<T17, T18>,
  f12: UnaryFunction<T16, T17>,
  f13: UnaryFunction<T15, T16>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T13, T14>,
  f16: UnaryFunction<T12, T13>,
  f17: UnaryFunction<T11, T12>,
  f18: UnaryFunction<T10, T11>,
  f19: UnaryFunction<T9, T10>,
  f20: UnaryFunction<T8, T9>,
  f21: UnaryFunction<T7, T8>,
  f22: UnaryFunction<T6, T7>,
  f23: UnaryFunction<T5, T6>,
  f24: UnaryFunction<T4, T5>,
  f25: UnaryFunction<T3, T4>,
  f26: UnaryFunction<T2, T3>,
  f27: UnaryFunction<T1, T2>,
  f28: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  TOut
>(
  f0: UnaryFunction<T29, TOut>,
  f1: UnaryFunction<T28, T29>,
  f2: UnaryFunction<T27, T28>,
  f3: UnaryFunction<T26, T27>,
  f4: UnaryFunction<T25, T26>,
  f5: UnaryFunction<T24, T25>,
  f6: UnaryFunction<T23, T24>,
  f7: UnaryFunction<T22, T23>,
  f8: UnaryFunction<T21, T22>,
  f9: UnaryFunction<T20, T21>,
  f10: UnaryFunction<T19, T20>,
  f11: UnaryFunction<T18, T19>,
  f12: UnaryFunction<T17, T18>,
  f13: UnaryFunction<T16, T17>,
  f14: UnaryFunction<T15, T16>,
  f15: UnaryFunction<T14, T15>,
  f16: UnaryFunction<T13, T14>,
  f17: UnaryFunction<T12, T13>,
  f18: UnaryFunction<T11, T12>,
  f19: UnaryFunction<T10, T11>,
  f20: UnaryFunction<T9, T10>,
  f21: UnaryFunction<T8, T9>,
  f22: UnaryFunction<T7, T8>,
  f23: UnaryFunction<T6, T7>,
  f24: UnaryFunction<T5, T6>,
  f25: UnaryFunction<T4, T5>,
  f26: UnaryFunction<T3, T4>,
  f27: UnaryFunction<T2, T3>,
  f28: UnaryFunction<T1, T2>,
  f29: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  TOut
>(
  f0: UnaryFunction<T30, TOut>,
  f1: UnaryFunction<T29, T30>,
  f2: UnaryFunction<T28, T29>,
  f3: UnaryFunction<T27, T28>,
  f4: UnaryFunction<T26, T27>,
  f5: UnaryFunction<T25, T26>,
  f6: UnaryFunction<T24, T25>,
  f7: UnaryFunction<T23, T24>,
  f8: UnaryFunction<T22, T23>,
  f9: UnaryFunction<T21, T22>,
  f10: UnaryFunction<T20, T21>,
  f11: UnaryFunction<T19, T20>,
  f12: UnaryFunction<T18, T19>,
  f13: UnaryFunction<T17, T18>,
  f14: UnaryFunction<T16, T17>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T14, T15>,
  f17: UnaryFunction<T13, T14>,
  f18: UnaryFunction<T12, T13>,
  f19: UnaryFunction<T11, T12>,
  f20: UnaryFunction<T10, T11>,
  f21: UnaryFunction<T9, T10>,
  f22: UnaryFunction<T8, T9>,
  f23: UnaryFunction<T7, T8>,
  f24: UnaryFunction<T6, T7>,
  f25: UnaryFunction<T5, T6>,
  f26: UnaryFunction<T4, T5>,
  f27: UnaryFunction<T3, T4>,
  f28: UnaryFunction<T2, T3>,
  f29: UnaryFunction<T1, T2>,
  f30: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  TOut
>(
  f0: UnaryFunction<T31, TOut>,
  f1: UnaryFunction<T30, T31>,
  f2: UnaryFunction<T29, T30>,
  f3: UnaryFunction<T28, T29>,
  f4: UnaryFunction<T27, T28>,
  f5: UnaryFunction<T26, T27>,
  f6: UnaryFunction<T25, T26>,
  f7: UnaryFunction<T24, T25>,
  f8: UnaryFunction<T23, T24>,
  f9: UnaryFunction<T22, T23>,
  f10: UnaryFunction<T21, T22>,
  f11: UnaryFunction<T20, T21>,
  f12: UnaryFunction<T19, T20>,
  f13: UnaryFunction<T18, T19>,
  f14: UnaryFunction<T17, T18>,
  f15: UnaryFunction<T16, T17>,
  f16: UnaryFunction<T15, T16>,
  f17: UnaryFunction<T14, T15>,
  f18: UnaryFunction<T13, T14>,
  f19: UnaryFunction<T12, T13>,
  f20: UnaryFunction<T11, T12>,
  f21: UnaryFunction<T10, T11>,
  f22: UnaryFunction<T9, T10>,
  f23: UnaryFunction<T8, T9>,
  f24: UnaryFunction<T7, T8>,
  f25: UnaryFunction<T6, T7>,
  f26: UnaryFunction<T5, T6>,
  f27: UnaryFunction<T4, T5>,
  f28: UnaryFunction<T3, T4>,
  f29: UnaryFunction<T2, T3>,
  f30: UnaryFunction<T1, T2>,
  f31: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  TOut
>(
  f0: UnaryFunction<T32, TOut>,
  f1: UnaryFunction<T31, T32>,
  f2: UnaryFunction<T30, T31>,
  f3: UnaryFunction<T29, T30>,
  f4: UnaryFunction<T28, T29>,
  f5: UnaryFunction<T27, T28>,
  f6: UnaryFunction<T26, T27>,
  f7: UnaryFunction<T25, T26>,
  f8: UnaryFunction<T24, T25>,
  f9: UnaryFunction<T23, T24>,
  f10: UnaryFunction<T22, T23>,
  f11: UnaryFunction<T21, T22>,
  f12: UnaryFunction<T20, T21>,
  f13: UnaryFunction<T19, T20>,
  f14: UnaryFunction<T18, T19>,
  f15: UnaryFunction<T17, T18>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T15, T16>,
  f18: UnaryFunction<T14, T15>,
  f19: UnaryFunction<T13, T14>,
  f20: UnaryFunction<T12, T13>,
  f21: UnaryFunction<T11, T12>,
  f22: UnaryFunction<T10, T11>,
  f23: UnaryFunction<T9, T10>,
  f24: UnaryFunction<T8, T9>,
  f25: UnaryFunction<T7, T8>,
  f26: UnaryFunction<T6, T7>,
  f27: UnaryFunction<T5, T6>,
  f28: UnaryFunction<T4, T5>,
  f29: UnaryFunction<T3, T4>,
  f30: UnaryFunction<T2, T3>,
  f31: UnaryFunction<T1, T2>,
  f32: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  TOut
>(
  f0: UnaryFunction<T33, TOut>,
  f1: UnaryFunction<T32, T33>,
  f2: UnaryFunction<T31, T32>,
  f3: UnaryFunction<T30, T31>,
  f4: UnaryFunction<T29, T30>,
  f5: UnaryFunction<T28, T29>,
  f6: UnaryFunction<T27, T28>,
  f7: UnaryFunction<T26, T27>,
  f8: UnaryFunction<T25, T26>,
  f9: UnaryFunction<T24, T25>,
  f10: UnaryFunction<T23, T24>,
  f11: UnaryFunction<T22, T23>,
  f12: UnaryFunction<T21, T22>,
  f13: UnaryFunction<T20, T21>,
  f14: UnaryFunction<T19, T20>,
  f15: UnaryFunction<T18, T19>,
  f16: UnaryFunction<T17, T18>,
  f17: UnaryFunction<T16, T17>,
  f18: UnaryFunction<T15, T16>,
  f19: UnaryFunction<T14, T15>,
  f20: UnaryFunction<T13, T14>,
  f21: UnaryFunction<T12, T13>,
  f22: UnaryFunction<T11, T12>,
  f23: UnaryFunction<T10, T11>,
  f24: UnaryFunction<T9, T10>,
  f25: UnaryFunction<T8, T9>,
  f26: UnaryFunction<T7, T8>,
  f27: UnaryFunction<T6, T7>,
  f28: UnaryFunction<T5, T6>,
  f29: UnaryFunction<T4, T5>,
  f30: UnaryFunction<T3, T4>,
  f31: UnaryFunction<T2, T3>,
  f32: UnaryFunction<T1, T2>,
  f33: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  TOut
>(
  f0: UnaryFunction<T34, TOut>,
  f1: UnaryFunction<T33, T34>,
  f2: UnaryFunction<T32, T33>,
  f3: UnaryFunction<T31, T32>,
  f4: UnaryFunction<T30, T31>,
  f5: UnaryFunction<T29, T30>,
  f6: UnaryFunction<T28, T29>,
  f7: UnaryFunction<T27, T28>,
  f8: UnaryFunction<T26, T27>,
  f9: UnaryFunction<T25, T26>,
  f10: UnaryFunction<T24, T25>,
  f11: UnaryFunction<T23, T24>,
  f12: UnaryFunction<T22, T23>,
  f13: UnaryFunction<T21, T22>,
  f14: UnaryFunction<T20, T21>,
  f15: UnaryFunction<T19, T20>,
  f16: UnaryFunction<T18, T19>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T16, T17>,
  f19: UnaryFunction<T15, T16>,
  f20: UnaryFunction<T14, T15>,
  f21: UnaryFunction<T13, T14>,
  f22: UnaryFunction<T12, T13>,
  f23: UnaryFunction<T11, T12>,
  f24: UnaryFunction<T10, T11>,
  f25: UnaryFunction<T9, T10>,
  f26: UnaryFunction<T8, T9>,
  f27: UnaryFunction<T7, T8>,
  f28: UnaryFunction<T6, T7>,
  f29: UnaryFunction<T5, T6>,
  f30: UnaryFunction<T4, T5>,
  f31: UnaryFunction<T3, T4>,
  f32: UnaryFunction<T2, T3>,
  f33: UnaryFunction<T1, T2>,
  f34: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  TOut
>(
  f0: UnaryFunction<T35, TOut>,
  f1: UnaryFunction<T34, T35>,
  f2: UnaryFunction<T33, T34>,
  f3: UnaryFunction<T32, T33>,
  f4: UnaryFunction<T31, T32>,
  f5: UnaryFunction<T30, T31>,
  f6: UnaryFunction<T29, T30>,
  f7: UnaryFunction<T28, T29>,
  f8: UnaryFunction<T27, T28>,
  f9: UnaryFunction<T26, T27>,
  f10: UnaryFunction<T25, T26>,
  f11: UnaryFunction<T24, T25>,
  f12: UnaryFunction<T23, T24>,
  f13: UnaryFunction<T22, T23>,
  f14: UnaryFunction<T21, T22>,
  f15: UnaryFunction<T20, T21>,
  f16: UnaryFunction<T19, T20>,
  f17: UnaryFunction<T18, T19>,
  f18: UnaryFunction<T17, T18>,
  f19: UnaryFunction<T16, T17>,
  f20: UnaryFunction<T15, T16>,
  f21: UnaryFunction<T14, T15>,
  f22: UnaryFunction<T13, T14>,
  f23: UnaryFunction<T12, T13>,
  f24: UnaryFunction<T11, T12>,
  f25: UnaryFunction<T10, T11>,
  f26: UnaryFunction<T9, T10>,
  f27: UnaryFunction<T8, T9>,
  f28: UnaryFunction<T7, T8>,
  f29: UnaryFunction<T6, T7>,
  f30: UnaryFunction<T5, T6>,
  f31: UnaryFunction<T4, T5>,
  f32: UnaryFunction<T3, T4>,
  f33: UnaryFunction<T2, T3>,
  f34: UnaryFunction<T1, T2>,
  f35: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  TOut
>(
  f0: UnaryFunction<T36, TOut>,
  f1: UnaryFunction<T35, T36>,
  f2: UnaryFunction<T34, T35>,
  f3: UnaryFunction<T33, T34>,
  f4: UnaryFunction<T32, T33>,
  f5: UnaryFunction<T31, T32>,
  f6: UnaryFunction<T30, T31>,
  f7: UnaryFunction<T29, T30>,
  f8: UnaryFunction<T28, T29>,
  f9: UnaryFunction<T27, T28>,
  f10: UnaryFunction<T26, T27>,
  f11: UnaryFunction<T25, T26>,
  f12: UnaryFunction<T24, T25>,
  f13: UnaryFunction<T23, T24>,
  f14: UnaryFunction<T22, T23>,
  f15: UnaryFunction<T21, T22>,
  f16: UnaryFunction<T20, T21>,
  f17: UnaryFunction<T19, T20>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T17, T18>,
  f20: UnaryFunction<T16, T17>,
  f21: UnaryFunction<T15, T16>,
  f22: UnaryFunction<T14, T15>,
  f23: UnaryFunction<T13, T14>,
  f24: UnaryFunction<T12, T13>,
  f25: UnaryFunction<T11, T12>,
  f26: UnaryFunction<T10, T11>,
  f27: UnaryFunction<T9, T10>,
  f28: UnaryFunction<T8, T9>,
  f29: UnaryFunction<T7, T8>,
  f30: UnaryFunction<T6, T7>,
  f31: UnaryFunction<T5, T6>,
  f32: UnaryFunction<T4, T5>,
  f33: UnaryFunction<T3, T4>,
  f34: UnaryFunction<T2, T3>,
  f35: UnaryFunction<T1, T2>,
  f36: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  TOut
>(
  f0: UnaryFunction<T37, TOut>,
  f1: UnaryFunction<T36, T37>,
  f2: UnaryFunction<T35, T36>,
  f3: UnaryFunction<T34, T35>,
  f4: UnaryFunction<T33, T34>,
  f5: UnaryFunction<T32, T33>,
  f6: UnaryFunction<T31, T32>,
  f7: UnaryFunction<T30, T31>,
  f8: UnaryFunction<T29, T30>,
  f9: UnaryFunction<T28, T29>,
  f10: UnaryFunction<T27, T28>,
  f11: UnaryFunction<T26, T27>,
  f12: UnaryFunction<T25, T26>,
  f13: UnaryFunction<T24, T25>,
  f14: UnaryFunction<T23, T24>,
  f15: UnaryFunction<T22, T23>,
  f16: UnaryFunction<T21, T22>,
  f17: UnaryFunction<T20, T21>,
  f18: UnaryFunction<T19, T20>,
  f19: UnaryFunction<T18, T19>,
  f20: UnaryFunction<T17, T18>,
  f21: UnaryFunction<T16, T17>,
  f22: UnaryFunction<T15, T16>,
  f23: UnaryFunction<T14, T15>,
  f24: UnaryFunction<T13, T14>,
  f25: UnaryFunction<T12, T13>,
  f26: UnaryFunction<T11, T12>,
  f27: UnaryFunction<T10, T11>,
  f28: UnaryFunction<T9, T10>,
  f29: UnaryFunction<T8, T9>,
  f30: UnaryFunction<T7, T8>,
  f31: UnaryFunction<T6, T7>,
  f32: UnaryFunction<T5, T6>,
  f33: UnaryFunction<T4, T5>,
  f34: UnaryFunction<T3, T4>,
  f35: UnaryFunction<T2, T3>,
  f36: UnaryFunction<T1, T2>,
  f37: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  TOut
>(
  f0: UnaryFunction<T38, TOut>,
  f1: UnaryFunction<T37, T38>,
  f2: UnaryFunction<T36, T37>,
  f3: UnaryFunction<T35, T36>,
  f4: UnaryFunction<T34, T35>,
  f5: UnaryFunction<T33, T34>,
  f6: UnaryFunction<T32, T33>,
  f7: UnaryFunction<T31, T32>,
  f8: UnaryFunction<T30, T31>,
  f9: UnaryFunction<T29, T30>,
  f10: UnaryFunction<T28, T29>,
  f11: UnaryFunction<T27, T28>,
  f12: UnaryFunction<T26, T27>,
  f13: UnaryFunction<T25, T26>,
  f14: UnaryFunction<T24, T25>,
  f15: UnaryFunction<T23, T24>,
  f16: UnaryFunction<T22, T23>,
  f17: UnaryFunction<T21, T22>,
  f18: UnaryFunction<T20, T21>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T18, T19>,
  f21: UnaryFunction<T17, T18>,
  f22: UnaryFunction<T16, T17>,
  f23: UnaryFunction<T15, T16>,
  f24: UnaryFunction<T14, T15>,
  f25: UnaryFunction<T13, T14>,
  f26: UnaryFunction<T12, T13>,
  f27: UnaryFunction<T11, T12>,
  f28: UnaryFunction<T10, T11>,
  f29: UnaryFunction<T9, T10>,
  f30: UnaryFunction<T8, T9>,
  f31: UnaryFunction<T7, T8>,
  f32: UnaryFunction<T6, T7>,
  f33: UnaryFunction<T5, T6>,
  f34: UnaryFunction<T4, T5>,
  f35: UnaryFunction<T3, T4>,
  f36: UnaryFunction<T2, T3>,
  f37: UnaryFunction<T1, T2>,
  f38: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  TOut
>(
  f0: UnaryFunction<T39, TOut>,
  f1: UnaryFunction<T38, T39>,
  f2: UnaryFunction<T37, T38>,
  f3: UnaryFunction<T36, T37>,
  f4: UnaryFunction<T35, T36>,
  f5: UnaryFunction<T34, T35>,
  f6: UnaryFunction<T33, T34>,
  f7: UnaryFunction<T32, T33>,
  f8: UnaryFunction<T31, T32>,
  f9: UnaryFunction<T30, T31>,
  f10: UnaryFunction<T29, T30>,
  f11: UnaryFunction<T28, T29>,
  f12: UnaryFunction<T27, T28>,
  f13: UnaryFunction<T26, T27>,
  f14: UnaryFunction<T25, T26>,
  f15: UnaryFunction<T24, T25>,
  f16: UnaryFunction<T23, T24>,
  f17: UnaryFunction<T22, T23>,
  f18: UnaryFunction<T21, T22>,
  f19: UnaryFunction<T20, T21>,
  f20: UnaryFunction<T19, T20>,
  f21: UnaryFunction<T18, T19>,
  f22: UnaryFunction<T17, T18>,
  f23: UnaryFunction<T16, T17>,
  f24: UnaryFunction<T15, T16>,
  f25: UnaryFunction<T14, T15>,
  f26: UnaryFunction<T13, T14>,
  f27: UnaryFunction<T12, T13>,
  f28: UnaryFunction<T11, T12>,
  f29: UnaryFunction<T10, T11>,
  f30: UnaryFunction<T9, T10>,
  f31: UnaryFunction<T8, T9>,
  f32: UnaryFunction<T7, T8>,
  f33: UnaryFunction<T6, T7>,
  f34: UnaryFunction<T5, T6>,
  f35: UnaryFunction<T4, T5>,
  f36: UnaryFunction<T3, T4>,
  f37: UnaryFunction<T2, T3>,
  f38: UnaryFunction<T1, T2>,
  f39: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  TOut
>(
  f0: UnaryFunction<T40, TOut>,
  f1: UnaryFunction<T39, T40>,
  f2: UnaryFunction<T38, T39>,
  f3: UnaryFunction<T37, T38>,
  f4: UnaryFunction<T36, T37>,
  f5: UnaryFunction<T35, T36>,
  f6: UnaryFunction<T34, T35>,
  f7: UnaryFunction<T33, T34>,
  f8: UnaryFunction<T32, T33>,
  f9: UnaryFunction<T31, T32>,
  f10: UnaryFunction<T30, T31>,
  f11: UnaryFunction<T29, T30>,
  f12: UnaryFunction<T28, T29>,
  f13: UnaryFunction<T27, T28>,
  f14: UnaryFunction<T26, T27>,
  f15: UnaryFunction<T25, T26>,
  f16: UnaryFunction<T24, T25>,
  f17: UnaryFunction<T23, T24>,
  f18: UnaryFunction<T22, T23>,
  f19: UnaryFunction<T21, T22>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T19, T20>,
  f22: UnaryFunction<T18, T19>,
  f23: UnaryFunction<T17, T18>,
  f24: UnaryFunction<T16, T17>,
  f25: UnaryFunction<T15, T16>,
  f26: UnaryFunction<T14, T15>,
  f27: UnaryFunction<T13, T14>,
  f28: UnaryFunction<T12, T13>,
  f29: UnaryFunction<T11, T12>,
  f30: UnaryFunction<T10, T11>,
  f31: UnaryFunction<T9, T10>,
  f32: UnaryFunction<T8, T9>,
  f33: UnaryFunction<T7, T8>,
  f34: UnaryFunction<T6, T7>,
  f35: UnaryFunction<T5, T6>,
  f36: UnaryFunction<T4, T5>,
  f37: UnaryFunction<T3, T4>,
  f38: UnaryFunction<T2, T3>,
  f39: UnaryFunction<T1, T2>,
  f40: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  TOut
>(
  f0: UnaryFunction<T41, TOut>,
  f1: UnaryFunction<T40, T41>,
  f2: UnaryFunction<T39, T40>,
  f3: UnaryFunction<T38, T39>,
  f4: UnaryFunction<T37, T38>,
  f5: UnaryFunction<T36, T37>,
  f6: UnaryFunction<T35, T36>,
  f7: UnaryFunction<T34, T35>,
  f8: UnaryFunction<T33, T34>,
  f9: UnaryFunction<T32, T33>,
  f10: UnaryFunction<T31, T32>,
  f11: UnaryFunction<T30, T31>,
  f12: UnaryFunction<T29, T30>,
  f13: UnaryFunction<T28, T29>,
  f14: UnaryFunction<T27, T28>,
  f15: UnaryFunction<T26, T27>,
  f16: UnaryFunction<T25, T26>,
  f17: UnaryFunction<T24, T25>,
  f18: UnaryFunction<T23, T24>,
  f19: UnaryFunction<T22, T23>,
  f20: UnaryFunction<T21, T22>,
  f21: UnaryFunction<T20, T21>,
  f22: UnaryFunction<T19, T20>,
  f23: UnaryFunction<T18, T19>,
  f24: UnaryFunction<T17, T18>,
  f25: UnaryFunction<T16, T17>,
  f26: UnaryFunction<T15, T16>,
  f27: UnaryFunction<T14, T15>,
  f28: UnaryFunction<T13, T14>,
  f29: UnaryFunction<T12, T13>,
  f30: UnaryFunction<T11, T12>,
  f31: UnaryFunction<T10, T11>,
  f32: UnaryFunction<T9, T10>,
  f33: UnaryFunction<T8, T9>,
  f34: UnaryFunction<T7, T8>,
  f35: UnaryFunction<T6, T7>,
  f36: UnaryFunction<T5, T6>,
  f37: UnaryFunction<T4, T5>,
  f38: UnaryFunction<T3, T4>,
  f39: UnaryFunction<T2, T3>,
  f40: UnaryFunction<T1, T2>,
  f41: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  TOut
>(
  f0: UnaryFunction<T42, TOut>,
  f1: UnaryFunction<T41, T42>,
  f2: UnaryFunction<T40, T41>,
  f3: UnaryFunction<T39, T40>,
  f4: UnaryFunction<T38, T39>,
  f5: UnaryFunction<T37, T38>,
  f6: UnaryFunction<T36, T37>,
  f7: UnaryFunction<T35, T36>,
  f8: UnaryFunction<T34, T35>,
  f9: UnaryFunction<T33, T34>,
  f10: UnaryFunction<T32, T33>,
  f11: UnaryFunction<T31, T32>,
  f12: UnaryFunction<T30, T31>,
  f13: UnaryFunction<T29, T30>,
  f14: UnaryFunction<T28, T29>,
  f15: UnaryFunction<T27, T28>,
  f16: UnaryFunction<T26, T27>,
  f17: UnaryFunction<T25, T26>,
  f18: UnaryFunction<T24, T25>,
  f19: UnaryFunction<T23, T24>,
  f20: UnaryFunction<T22, T23>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T20, T21>,
  f23: UnaryFunction<T19, T20>,
  f24: UnaryFunction<T18, T19>,
  f25: UnaryFunction<T17, T18>,
  f26: UnaryFunction<T16, T17>,
  f27: UnaryFunction<T15, T16>,
  f28: UnaryFunction<T14, T15>,
  f29: UnaryFunction<T13, T14>,
  f30: UnaryFunction<T12, T13>,
  f31: UnaryFunction<T11, T12>,
  f32: UnaryFunction<T10, T11>,
  f33: UnaryFunction<T9, T10>,
  f34: UnaryFunction<T8, T9>,
  f35: UnaryFunction<T7, T8>,
  f36: UnaryFunction<T6, T7>,
  f37: UnaryFunction<T5, T6>,
  f38: UnaryFunction<T4, T5>,
  f39: UnaryFunction<T3, T4>,
  f40: UnaryFunction<T2, T3>,
  f41: UnaryFunction<T1, T2>,
  f42: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  TOut
>(
  f0: UnaryFunction<T43, TOut>,
  f1: UnaryFunction<T42, T43>,
  f2: UnaryFunction<T41, T42>,
  f3: UnaryFunction<T40, T41>,
  f4: UnaryFunction<T39, T40>,
  f5: UnaryFunction<T38, T39>,
  f6: UnaryFunction<T37, T38>,
  f7: UnaryFunction<T36, T37>,
  f8: UnaryFunction<T35, T36>,
  f9: UnaryFunction<T34, T35>,
  f10: UnaryFunction<T33, T34>,
  f11: UnaryFunction<T32, T33>,
  f12: UnaryFunction<T31, T32>,
  f13: UnaryFunction<T30, T31>,
  f14: UnaryFunction<T29, T30>,
  f15: UnaryFunction<T28, T29>,
  f16: UnaryFunction<T27, T28>,
  f17: UnaryFunction<T26, T27>,
  f18: UnaryFunction<T25, T26>,
  f19: UnaryFunction<T24, T25>,
  f20: UnaryFunction<T23, T24>,
  f21: UnaryFunction<T22, T23>,
  f22: UnaryFunction<T21, T22>,
  f23: UnaryFunction<T20, T21>,
  f24: UnaryFunction<T19, T20>,
  f25: UnaryFunction<T18, T19>,
  f26: UnaryFunction<T17, T18>,
  f27: UnaryFunction<T16, T17>,
  f28: UnaryFunction<T15, T16>,
  f29: UnaryFunction<T14, T15>,
  f30: UnaryFunction<T13, T14>,
  f31: UnaryFunction<T12, T13>,
  f32: UnaryFunction<T11, T12>,
  f33: UnaryFunction<T10, T11>,
  f34: UnaryFunction<T9, T10>,
  f35: UnaryFunction<T8, T9>,
  f36: UnaryFunction<T7, T8>,
  f37: UnaryFunction<T6, T7>,
  f38: UnaryFunction<T5, T6>,
  f39: UnaryFunction<T4, T5>,
  f40: UnaryFunction<T3, T4>,
  f41: UnaryFunction<T2, T3>,
  f42: UnaryFunction<T1, T2>,
  f43: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  TOut
>(
  f0: UnaryFunction<T44, TOut>,
  f1: UnaryFunction<T43, T44>,
  f2: UnaryFunction<T42, T43>,
  f3: UnaryFunction<T41, T42>,
  f4: UnaryFunction<T40, T41>,
  f5: UnaryFunction<T39, T40>,
  f6: UnaryFunction<T38, T39>,
  f7: UnaryFunction<T37, T38>,
  f8: UnaryFunction<T36, T37>,
  f9: UnaryFunction<T35, T36>,
  f10: UnaryFunction<T34, T35>,
  f11: UnaryFunction<T33, T34>,
  f12: UnaryFunction<T32, T33>,
  f13: UnaryFunction<T31, T32>,
  f14: UnaryFunction<T30, T31>,
  f15: UnaryFunction<T29, T30>,
  f16: UnaryFunction<T28, T29>,
  f17: UnaryFunction<T27, T28>,
  f18: UnaryFunction<T26, T27>,
  f19: UnaryFunction<T25, T26>,
  f20: UnaryFunction<T24, T25>,
  f21: UnaryFunction<T23, T24>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T21, T22>,
  f24: UnaryFunction<T20, T21>,
  f25: UnaryFunction<T19, T20>,
  f26: UnaryFunction<T18, T19>,
  f27: UnaryFunction<T17, T18>,
  f28: UnaryFunction<T16, T17>,
  f29: UnaryFunction<T15, T16>,
  f30: UnaryFunction<T14, T15>,
  f31: UnaryFunction<T13, T14>,
  f32: UnaryFunction<T12, T13>,
  f33: UnaryFunction<T11, T12>,
  f34: UnaryFunction<T10, T11>,
  f35: UnaryFunction<T9, T10>,
  f36: UnaryFunction<T8, T9>,
  f37: UnaryFunction<T7, T8>,
  f38: UnaryFunction<T6, T7>,
  f39: UnaryFunction<T5, T6>,
  f40: UnaryFunction<T4, T5>,
  f41: UnaryFunction<T3, T4>,
  f42: UnaryFunction<T2, T3>,
  f43: UnaryFunction<T1, T2>,
  f44: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  TOut
>(
  f0: UnaryFunction<T45, TOut>,
  f1: UnaryFunction<T44, T45>,
  f2: UnaryFunction<T43, T44>,
  f3: UnaryFunction<T42, T43>,
  f4: UnaryFunction<T41, T42>,
  f5: UnaryFunction<T40, T41>,
  f6: UnaryFunction<T39, T40>,
  f7: UnaryFunction<T38, T39>,
  f8: UnaryFunction<T37, T38>,
  f9: UnaryFunction<T36, T37>,
  f10: UnaryFunction<T35, T36>,
  f11: UnaryFunction<T34, T35>,
  f12: UnaryFunction<T33, T34>,
  f13: UnaryFunction<T32, T33>,
  f14: UnaryFunction<T31, T32>,
  f15: UnaryFunction<T30, T31>,
  f16: UnaryFunction<T29, T30>,
  f17: UnaryFunction<T28, T29>,
  f18: UnaryFunction<T27, T28>,
  f19: UnaryFunction<T26, T27>,
  f20: UnaryFunction<T25, T26>,
  f21: UnaryFunction<T24, T25>,
  f22: UnaryFunction<T23, T24>,
  f23: UnaryFunction<T22, T23>,
  f24: UnaryFunction<T21, T22>,
  f25: UnaryFunction<T20, T21>,
  f26: UnaryFunction<T19, T20>,
  f27: UnaryFunction<T18, T19>,
  f28: UnaryFunction<T17, T18>,
  f29: UnaryFunction<T16, T17>,
  f30: UnaryFunction<T15, T16>,
  f31: UnaryFunction<T14, T15>,
  f32: UnaryFunction<T13, T14>,
  f33: UnaryFunction<T12, T13>,
  f34: UnaryFunction<T11, T12>,
  f35: UnaryFunction<T10, T11>,
  f36: UnaryFunction<T9, T10>,
  f37: UnaryFunction<T8, T9>,
  f38: UnaryFunction<T7, T8>,
  f39: UnaryFunction<T6, T7>,
  f40: UnaryFunction<T5, T6>,
  f41: UnaryFunction<T4, T5>,
  f42: UnaryFunction<T3, T4>,
  f43: UnaryFunction<T2, T3>,
  f44: UnaryFunction<T1, T2>,
  f45: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  TOut
>(
  f0: UnaryFunction<T46, TOut>,
  f1: UnaryFunction<T45, T46>,
  f2: UnaryFunction<T44, T45>,
  f3: UnaryFunction<T43, T44>,
  f4: UnaryFunction<T42, T43>,
  f5: UnaryFunction<T41, T42>,
  f6: UnaryFunction<T40, T41>,
  f7: UnaryFunction<T39, T40>,
  f8: UnaryFunction<T38, T39>,
  f9: UnaryFunction<T37, T38>,
  f10: UnaryFunction<T36, T37>,
  f11: UnaryFunction<T35, T36>,
  f12: UnaryFunction<T34, T35>,
  f13: UnaryFunction<T33, T34>,
  f14: UnaryFunction<T32, T33>,
  f15: UnaryFunction<T31, T32>,
  f16: UnaryFunction<T30, T31>,
  f17: UnaryFunction<T29, T30>,
  f18: UnaryFunction<T28, T29>,
  f19: UnaryFunction<T27, T28>,
  f20: UnaryFunction<T26, T27>,
  f21: UnaryFunction<T25, T26>,
  f22: UnaryFunction<T24, T25>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T22, T23>,
  f25: UnaryFunction<T21, T22>,
  f26: UnaryFunction<T20, T21>,
  f27: UnaryFunction<T19, T20>,
  f28: UnaryFunction<T18, T19>,
  f29: UnaryFunction<T17, T18>,
  f30: UnaryFunction<T16, T17>,
  f31: UnaryFunction<T15, T16>,
  f32: UnaryFunction<T14, T15>,
  f33: UnaryFunction<T13, T14>,
  f34: UnaryFunction<T12, T13>,
  f35: UnaryFunction<T11, T12>,
  f36: UnaryFunction<T10, T11>,
  f37: UnaryFunction<T9, T10>,
  f38: UnaryFunction<T8, T9>,
  f39: UnaryFunction<T7, T8>,
  f40: UnaryFunction<T6, T7>,
  f41: UnaryFunction<T5, T6>,
  f42: UnaryFunction<T4, T5>,
  f43: UnaryFunction<T3, T4>,
  f44: UnaryFunction<T2, T3>,
  f45: UnaryFunction<T1, T2>,
  f46: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  TOut
>(
  f0: UnaryFunction<T47, TOut>,
  f1: UnaryFunction<T46, T47>,
  f2: UnaryFunction<T45, T46>,
  f3: UnaryFunction<T44, T45>,
  f4: UnaryFunction<T43, T44>,
  f5: UnaryFunction<T42, T43>,
  f6: UnaryFunction<T41, T42>,
  f7: UnaryFunction<T40, T41>,
  f8: UnaryFunction<T39, T40>,
  f9: UnaryFunction<T38, T39>,
  f10: UnaryFunction<T37, T38>,
  f11: UnaryFunction<T36, T37>,
  f12: UnaryFunction<T35, T36>,
  f13: UnaryFunction<T34, T35>,
  f14: UnaryFunction<T33, T34>,
  f15: UnaryFunction<T32, T33>,
  f16: UnaryFunction<T31, T32>,
  f17: UnaryFunction<T30, T31>,
  f18: UnaryFunction<T29, T30>,
  f19: UnaryFunction<T28, T29>,
  f20: UnaryFunction<T27, T28>,
  f21: UnaryFunction<T26, T27>,
  f22: UnaryFunction<T25, T26>,
  f23: UnaryFunction<T24, T25>,
  f24: UnaryFunction<T23, T24>,
  f25: UnaryFunction<T22, T23>,
  f26: UnaryFunction<T21, T22>,
  f27: UnaryFunction<T20, T21>,
  f28: UnaryFunction<T19, T20>,
  f29: UnaryFunction<T18, T19>,
  f30: UnaryFunction<T17, T18>,
  f31: UnaryFunction<T16, T17>,
  f32: UnaryFunction<T15, T16>,
  f33: UnaryFunction<T14, T15>,
  f34: UnaryFunction<T13, T14>,
  f35: UnaryFunction<T12, T13>,
  f36: UnaryFunction<T11, T12>,
  f37: UnaryFunction<T10, T11>,
  f38: UnaryFunction<T9, T10>,
  f39: UnaryFunction<T8, T9>,
  f40: UnaryFunction<T7, T8>,
  f41: UnaryFunction<T6, T7>,
  f42: UnaryFunction<T5, T6>,
  f43: UnaryFunction<T4, T5>,
  f44: UnaryFunction<T3, T4>,
  f45: UnaryFunction<T2, T3>,
  f46: UnaryFunction<T1, T2>,
  f47: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  TOut
>(
  f0: UnaryFunction<T48, TOut>,
  f1: UnaryFunction<T47, T48>,
  f2: UnaryFunction<T46, T47>,
  f3: UnaryFunction<T45, T46>,
  f4: UnaryFunction<T44, T45>,
  f5: UnaryFunction<T43, T44>,
  f6: UnaryFunction<T42, T43>,
  f7: UnaryFunction<T41, T42>,
  f8: UnaryFunction<T40, T41>,
  f9: UnaryFunction<T39, T40>,
  f10: UnaryFunction<T38, T39>,
  f11: UnaryFunction<T37, T38>,
  f12: UnaryFunction<T36, T37>,
  f13: UnaryFunction<T35, T36>,
  f14: UnaryFunction<T34, T35>,
  f15: UnaryFunction<T33, T34>,
  f16: UnaryFunction<T32, T33>,
  f17: UnaryFunction<T31, T32>,
  f18: UnaryFunction<T30, T31>,
  f19: UnaryFunction<T29, T30>,
  f20: UnaryFunction<T28, T29>,
  f21: UnaryFunction<T27, T28>,
  f22: UnaryFunction<T26, T27>,
  f23: UnaryFunction<T25, T26>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T23, T24>,
  f26: UnaryFunction<T22, T23>,
  f27: UnaryFunction<T21, T22>,
  f28: UnaryFunction<T20, T21>,
  f29: UnaryFunction<T19, T20>,
  f30: UnaryFunction<T18, T19>,
  f31: UnaryFunction<T17, T18>,
  f32: UnaryFunction<T16, T17>,
  f33: UnaryFunction<T15, T16>,
  f34: UnaryFunction<T14, T15>,
  f35: UnaryFunction<T13, T14>,
  f36: UnaryFunction<T12, T13>,
  f37: UnaryFunction<T11, T12>,
  f38: UnaryFunction<T10, T11>,
  f39: UnaryFunction<T9, T10>,
  f40: UnaryFunction<T8, T9>,
  f41: UnaryFunction<T7, T8>,
  f42: UnaryFunction<T6, T7>,
  f43: UnaryFunction<T5, T6>,
  f44: UnaryFunction<T4, T5>,
  f45: UnaryFunction<T3, T4>,
  f46: UnaryFunction<T2, T3>,
  f47: UnaryFunction<T1, T2>,
  f48: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  TOut
>(
  f0: UnaryFunction<T49, TOut>,
  f1: UnaryFunction<T48, T49>,
  f2: UnaryFunction<T47, T48>,
  f3: UnaryFunction<T46, T47>,
  f4: UnaryFunction<T45, T46>,
  f5: UnaryFunction<T44, T45>,
  f6: UnaryFunction<T43, T44>,
  f7: UnaryFunction<T42, T43>,
  f8: UnaryFunction<T41, T42>,
  f9: UnaryFunction<T40, T41>,
  f10: UnaryFunction<T39, T40>,
  f11: UnaryFunction<T38, T39>,
  f12: UnaryFunction<T37, T38>,
  f13: UnaryFunction<T36, T37>,
  f14: UnaryFunction<T35, T36>,
  f15: UnaryFunction<T34, T35>,
  f16: UnaryFunction<T33, T34>,
  f17: UnaryFunction<T32, T33>,
  f18: UnaryFunction<T31, T32>,
  f19: UnaryFunction<T30, T31>,
  f20: UnaryFunction<T29, T30>,
  f21: UnaryFunction<T28, T29>,
  f22: UnaryFunction<T27, T28>,
  f23: UnaryFunction<T26, T27>,
  f24: UnaryFunction<T25, T26>,
  f25: UnaryFunction<T24, T25>,
  f26: UnaryFunction<T23, T24>,
  f27: UnaryFunction<T22, T23>,
  f28: UnaryFunction<T21, T22>,
  f29: UnaryFunction<T20, T21>,
  f30: UnaryFunction<T19, T20>,
  f31: UnaryFunction<T18, T19>,
  f32: UnaryFunction<T17, T18>,
  f33: UnaryFunction<T16, T17>,
  f34: UnaryFunction<T15, T16>,
  f35: UnaryFunction<T14, T15>,
  f36: UnaryFunction<T13, T14>,
  f37: UnaryFunction<T12, T13>,
  f38: UnaryFunction<T11, T12>,
  f39: UnaryFunction<T10, T11>,
  f40: UnaryFunction<T9, T10>,
  f41: UnaryFunction<T8, T9>,
  f42: UnaryFunction<T7, T8>,
  f43: UnaryFunction<T6, T7>,
  f44: UnaryFunction<T5, T6>,
  f45: UnaryFunction<T4, T5>,
  f46: UnaryFunction<T3, T4>,
  f47: UnaryFunction<T2, T3>,
  f48: UnaryFunction<T1, T2>,
  f49: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  TOut
>(
  f0: UnaryFunction<T50, TOut>,
  f1: UnaryFunction<T49, T50>,
  f2: UnaryFunction<T48, T49>,
  f3: UnaryFunction<T47, T48>,
  f4: UnaryFunction<T46, T47>,
  f5: UnaryFunction<T45, T46>,
  f6: UnaryFunction<T44, T45>,
  f7: UnaryFunction<T43, T44>,
  f8: UnaryFunction<T42, T43>,
  f9: UnaryFunction<T41, T42>,
  f10: UnaryFunction<T40, T41>,
  f11: UnaryFunction<T39, T40>,
  f12: UnaryFunction<T38, T39>,
  f13: UnaryFunction<T37, T38>,
  f14: UnaryFunction<T36, T37>,
  f15: UnaryFunction<T35, T36>,
  f16: UnaryFunction<T34, T35>,
  f17: UnaryFunction<T33, T34>,
  f18: UnaryFunction<T32, T33>,
  f19: UnaryFunction<T31, T32>,
  f20: UnaryFunction<T30, T31>,
  f21: UnaryFunction<T29, T30>,
  f22: UnaryFunction<T28, T29>,
  f23: UnaryFunction<T27, T28>,
  f24: UnaryFunction<T26, T27>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T24, T25>,
  f27: UnaryFunction<T23, T24>,
  f28: UnaryFunction<T22, T23>,
  f29: UnaryFunction<T21, T22>,
  f30: UnaryFunction<T20, T21>,
  f31: UnaryFunction<T19, T20>,
  f32: UnaryFunction<T18, T19>,
  f33: UnaryFunction<T17, T18>,
  f34: UnaryFunction<T16, T17>,
  f35: UnaryFunction<T15, T16>,
  f36: UnaryFunction<T14, T15>,
  f37: UnaryFunction<T13, T14>,
  f38: UnaryFunction<T12, T13>,
  f39: UnaryFunction<T11, T12>,
  f40: UnaryFunction<T10, T11>,
  f41: UnaryFunction<T9, T10>,
  f42: UnaryFunction<T8, T9>,
  f43: UnaryFunction<T7, T8>,
  f44: UnaryFunction<T6, T7>,
  f45: UnaryFunction<T5, T6>,
  f46: UnaryFunction<T4, T5>,
  f47: UnaryFunction<T3, T4>,
  f48: UnaryFunction<T2, T3>,
  f49: UnaryFunction<T1, T2>,
  f50: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  TOut
>(
  f0: UnaryFunction<T51, TOut>,
  f1: UnaryFunction<T50, T51>,
  f2: UnaryFunction<T49, T50>,
  f3: UnaryFunction<T48, T49>,
  f4: UnaryFunction<T47, T48>,
  f5: UnaryFunction<T46, T47>,
  f6: UnaryFunction<T45, T46>,
  f7: UnaryFunction<T44, T45>,
  f8: UnaryFunction<T43, T44>,
  f9: UnaryFunction<T42, T43>,
  f10: UnaryFunction<T41, T42>,
  f11: UnaryFunction<T40, T41>,
  f12: UnaryFunction<T39, T40>,
  f13: UnaryFunction<T38, T39>,
  f14: UnaryFunction<T37, T38>,
  f15: UnaryFunction<T36, T37>,
  f16: UnaryFunction<T35, T36>,
  f17: UnaryFunction<T34, T35>,
  f18: UnaryFunction<T33, T34>,
  f19: UnaryFunction<T32, T33>,
  f20: UnaryFunction<T31, T32>,
  f21: UnaryFunction<T30, T31>,
  f22: UnaryFunction<T29, T30>,
  f23: UnaryFunction<T28, T29>,
  f24: UnaryFunction<T27, T28>,
  f25: UnaryFunction<T26, T27>,
  f26: UnaryFunction<T25, T26>,
  f27: UnaryFunction<T24, T25>,
  f28: UnaryFunction<T23, T24>,
  f29: UnaryFunction<T22, T23>,
  f30: UnaryFunction<T21, T22>,
  f31: UnaryFunction<T20, T21>,
  f32: UnaryFunction<T19, T20>,
  f33: UnaryFunction<T18, T19>,
  f34: UnaryFunction<T17, T18>,
  f35: UnaryFunction<T16, T17>,
  f36: UnaryFunction<T15, T16>,
  f37: UnaryFunction<T14, T15>,
  f38: UnaryFunction<T13, T14>,
  f39: UnaryFunction<T12, T13>,
  f40: UnaryFunction<T11, T12>,
  f41: UnaryFunction<T10, T11>,
  f42: UnaryFunction<T9, T10>,
  f43: UnaryFunction<T8, T9>,
  f44: UnaryFunction<T7, T8>,
  f45: UnaryFunction<T6, T7>,
  f46: UnaryFunction<T5, T6>,
  f47: UnaryFunction<T4, T5>,
  f48: UnaryFunction<T3, T4>,
  f49: UnaryFunction<T2, T3>,
  f50: UnaryFunction<T1, T2>,
  f51: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  TOut
>(
  f0: UnaryFunction<T52, TOut>,
  f1: UnaryFunction<T51, T52>,
  f2: UnaryFunction<T50, T51>,
  f3: UnaryFunction<T49, T50>,
  f4: UnaryFunction<T48, T49>,
  f5: UnaryFunction<T47, T48>,
  f6: UnaryFunction<T46, T47>,
  f7: UnaryFunction<T45, T46>,
  f8: UnaryFunction<T44, T45>,
  f9: UnaryFunction<T43, T44>,
  f10: UnaryFunction<T42, T43>,
  f11: UnaryFunction<T41, T42>,
  f12: UnaryFunction<T40, T41>,
  f13: UnaryFunction<T39, T40>,
  f14: UnaryFunction<T38, T39>,
  f15: UnaryFunction<T37, T38>,
  f16: UnaryFunction<T36, T37>,
  f17: UnaryFunction<T35, T36>,
  f18: UnaryFunction<T34, T35>,
  f19: UnaryFunction<T33, T34>,
  f20: UnaryFunction<T32, T33>,
  f21: UnaryFunction<T31, T32>,
  f22: UnaryFunction<T30, T31>,
  f23: UnaryFunction<T29, T30>,
  f24: UnaryFunction<T28, T29>,
  f25: UnaryFunction<T27, T28>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T25, T26>,
  f28: UnaryFunction<T24, T25>,
  f29: UnaryFunction<T23, T24>,
  f30: UnaryFunction<T22, T23>,
  f31: UnaryFunction<T21, T22>,
  f32: UnaryFunction<T20, T21>,
  f33: UnaryFunction<T19, T20>,
  f34: UnaryFunction<T18, T19>,
  f35: UnaryFunction<T17, T18>,
  f36: UnaryFunction<T16, T17>,
  f37: UnaryFunction<T15, T16>,
  f38: UnaryFunction<T14, T15>,
  f39: UnaryFunction<T13, T14>,
  f40: UnaryFunction<T12, T13>,
  f41: UnaryFunction<T11, T12>,
  f42: UnaryFunction<T10, T11>,
  f43: UnaryFunction<T9, T10>,
  f44: UnaryFunction<T8, T9>,
  f45: UnaryFunction<T7, T8>,
  f46: UnaryFunction<T6, T7>,
  f47: UnaryFunction<T5, T6>,
  f48: UnaryFunction<T4, T5>,
  f49: UnaryFunction<T3, T4>,
  f50: UnaryFunction<T2, T3>,
  f51: UnaryFunction<T1, T2>,
  f52: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  TOut
>(
  f0: UnaryFunction<T53, TOut>,
  f1: UnaryFunction<T52, T53>,
  f2: UnaryFunction<T51, T52>,
  f3: UnaryFunction<T50, T51>,
  f4: UnaryFunction<T49, T50>,
  f5: UnaryFunction<T48, T49>,
  f6: UnaryFunction<T47, T48>,
  f7: UnaryFunction<T46, T47>,
  f8: UnaryFunction<T45, T46>,
  f9: UnaryFunction<T44, T45>,
  f10: UnaryFunction<T43, T44>,
  f11: UnaryFunction<T42, T43>,
  f12: UnaryFunction<T41, T42>,
  f13: UnaryFunction<T40, T41>,
  f14: UnaryFunction<T39, T40>,
  f15: UnaryFunction<T38, T39>,
  f16: UnaryFunction<T37, T38>,
  f17: UnaryFunction<T36, T37>,
  f18: UnaryFunction<T35, T36>,
  f19: UnaryFunction<T34, T35>,
  f20: UnaryFunction<T33, T34>,
  f21: UnaryFunction<T32, T33>,
  f22: UnaryFunction<T31, T32>,
  f23: UnaryFunction<T30, T31>,
  f24: UnaryFunction<T29, T30>,
  f25: UnaryFunction<T28, T29>,
  f26: UnaryFunction<T27, T28>,
  f27: UnaryFunction<T26, T27>,
  f28: UnaryFunction<T25, T26>,
  f29: UnaryFunction<T24, T25>,
  f30: UnaryFunction<T23, T24>,
  f31: UnaryFunction<T22, T23>,
  f32: UnaryFunction<T21, T22>,
  f33: UnaryFunction<T20, T21>,
  f34: UnaryFunction<T19, T20>,
  f35: UnaryFunction<T18, T19>,
  f36: UnaryFunction<T17, T18>,
  f37: UnaryFunction<T16, T17>,
  f38: UnaryFunction<T15, T16>,
  f39: UnaryFunction<T14, T15>,
  f40: UnaryFunction<T13, T14>,
  f41: UnaryFunction<T12, T13>,
  f42: UnaryFunction<T11, T12>,
  f43: UnaryFunction<T10, T11>,
  f44: UnaryFunction<T9, T10>,
  f45: UnaryFunction<T8, T9>,
  f46: UnaryFunction<T7, T8>,
  f47: UnaryFunction<T6, T7>,
  f48: UnaryFunction<T5, T6>,
  f49: UnaryFunction<T4, T5>,
  f50: UnaryFunction<T3, T4>,
  f51: UnaryFunction<T2, T3>,
  f52: UnaryFunction<T1, T2>,
  f53: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  TOut
>(
  f0: UnaryFunction<T54, TOut>,
  f1: UnaryFunction<T53, T54>,
  f2: UnaryFunction<T52, T53>,
  f3: UnaryFunction<T51, T52>,
  f4: UnaryFunction<T50, T51>,
  f5: UnaryFunction<T49, T50>,
  f6: UnaryFunction<T48, T49>,
  f7: UnaryFunction<T47, T48>,
  f8: UnaryFunction<T46, T47>,
  f9: UnaryFunction<T45, T46>,
  f10: UnaryFunction<T44, T45>,
  f11: UnaryFunction<T43, T44>,
  f12: UnaryFunction<T42, T43>,
  f13: UnaryFunction<T41, T42>,
  f14: UnaryFunction<T40, T41>,
  f15: UnaryFunction<T39, T40>,
  f16: UnaryFunction<T38, T39>,
  f17: UnaryFunction<T37, T38>,
  f18: UnaryFunction<T36, T37>,
  f19: UnaryFunction<T35, T36>,
  f20: UnaryFunction<T34, T35>,
  f21: UnaryFunction<T33, T34>,
  f22: UnaryFunction<T32, T33>,
  f23: UnaryFunction<T31, T32>,
  f24: UnaryFunction<T30, T31>,
  f25: UnaryFunction<T29, T30>,
  f26: UnaryFunction<T28, T29>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T26, T27>,
  f29: UnaryFunction<T25, T26>,
  f30: UnaryFunction<T24, T25>,
  f31: UnaryFunction<T23, T24>,
  f32: UnaryFunction<T22, T23>,
  f33: UnaryFunction<T21, T22>,
  f34: UnaryFunction<T20, T21>,
  f35: UnaryFunction<T19, T20>,
  f36: UnaryFunction<T18, T19>,
  f37: UnaryFunction<T17, T18>,
  f38: UnaryFunction<T16, T17>,
  f39: UnaryFunction<T15, T16>,
  f40: UnaryFunction<T14, T15>,
  f41: UnaryFunction<T13, T14>,
  f42: UnaryFunction<T12, T13>,
  f43: UnaryFunction<T11, T12>,
  f44: UnaryFunction<T10, T11>,
  f45: UnaryFunction<T9, T10>,
  f46: UnaryFunction<T8, T9>,
  f47: UnaryFunction<T7, T8>,
  f48: UnaryFunction<T6, T7>,
  f49: UnaryFunction<T5, T6>,
  f50: UnaryFunction<T4, T5>,
  f51: UnaryFunction<T3, T4>,
  f52: UnaryFunction<T2, T3>,
  f53: UnaryFunction<T1, T2>,
  f54: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  T55,
  TOut
>(
  f0: UnaryFunction<T55, TOut>,
  f1: UnaryFunction<T54, T55>,
  f2: UnaryFunction<T53, T54>,
  f3: UnaryFunction<T52, T53>,
  f4: UnaryFunction<T51, T52>,
  f5: UnaryFunction<T50, T51>,
  f6: UnaryFunction<T49, T50>,
  f7: UnaryFunction<T48, T49>,
  f8: UnaryFunction<T47, T48>,
  f9: UnaryFunction<T46, T47>,
  f10: UnaryFunction<T45, T46>,
  f11: UnaryFunction<T44, T45>,
  f12: UnaryFunction<T43, T44>,
  f13: UnaryFunction<T42, T43>,
  f14: UnaryFunction<T41, T42>,
  f15: UnaryFunction<T40, T41>,
  f16: UnaryFunction<T39, T40>,
  f17: UnaryFunction<T38, T39>,
  f18: UnaryFunction<T37, T38>,
  f19: UnaryFunction<T36, T37>,
  f20: UnaryFunction<T35, T36>,
  f21: UnaryFunction<T34, T35>,
  f22: UnaryFunction<T33, T34>,
  f23: UnaryFunction<T32, T33>,
  f24: UnaryFunction<T31, T32>,
  f25: UnaryFunction<T30, T31>,
  f26: UnaryFunction<T29, T30>,
  f27: UnaryFunction<T28, T29>,
  f28: UnaryFunction<T27, T28>,
  f29: UnaryFunction<T26, T27>,
  f30: UnaryFunction<T25, T26>,
  f31: UnaryFunction<T24, T25>,
  f32: UnaryFunction<T23, T24>,
  f33: UnaryFunction<T22, T23>,
  f34: UnaryFunction<T21, T22>,
  f35: UnaryFunction<T20, T21>,
  f36: UnaryFunction<T19, T20>,
  f37: UnaryFunction<T18, T19>,
  f38: UnaryFunction<T17, T18>,
  f39: UnaryFunction<T16, T17>,
  f40: UnaryFunction<T15, T16>,
  f41: UnaryFunction<T14, T15>,
  f42: UnaryFunction<T13, T14>,
  f43: UnaryFunction<T12, T13>,
  f44: UnaryFunction<T11, T12>,
  f45: UnaryFunction<T10, T11>,
  f46: UnaryFunction<T9, T10>,
  f47: UnaryFunction<T8, T9>,
  f48: UnaryFunction<T7, T8>,
  f49: UnaryFunction<T6, T7>,
  f50: UnaryFunction<T5, T6>,
  f51: UnaryFunction<T4, T5>,
  f52: UnaryFunction<T3, T4>,
  f53: UnaryFunction<T2, T3>,
  f54: UnaryFunction<T1, T2>,
  f55: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  T55,
  T56,
  TOut
>(
  f0: UnaryFunction<T56, TOut>,
  f1: UnaryFunction<T55, T56>,
  f2: UnaryFunction<T54, T55>,
  f3: UnaryFunction<T53, T54>,
  f4: UnaryFunction<T52, T53>,
  f5: UnaryFunction<T51, T52>,
  f6: UnaryFunction<T50, T51>,
  f7: UnaryFunction<T49, T50>,
  f8: UnaryFunction<T48, T49>,
  f9: UnaryFunction<T47, T48>,
  f10: UnaryFunction<T46, T47>,
  f11: UnaryFunction<T45, T46>,
  f12: UnaryFunction<T44, T45>,
  f13: UnaryFunction<T43, T44>,
  f14: UnaryFunction<T42, T43>,
  f15: UnaryFunction<T41, T42>,
  f16: UnaryFunction<T40, T41>,
  f17: UnaryFunction<T39, T40>,
  f18: UnaryFunction<T38, T39>,
  f19: UnaryFunction<T37, T38>,
  f20: UnaryFunction<T36, T37>,
  f21: UnaryFunction<T35, T36>,
  f22: UnaryFunction<T34, T35>,
  f23: UnaryFunction<T33, T34>,
  f24: UnaryFunction<T32, T33>,
  f25: UnaryFunction<T31, T32>,
  f26: UnaryFunction<T30, T31>,
  f27: UnaryFunction<T29, T30>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T27, T28>,
  f30: UnaryFunction<T26, T27>,
  f31: UnaryFunction<T25, T26>,
  f32: UnaryFunction<T24, T25>,
  f33: UnaryFunction<T23, T24>,
  f34: UnaryFunction<T22, T23>,
  f35: UnaryFunction<T21, T22>,
  f36: UnaryFunction<T20, T21>,
  f37: UnaryFunction<T19, T20>,
  f38: UnaryFunction<T18, T19>,
  f39: UnaryFunction<T17, T18>,
  f40: UnaryFunction<T16, T17>,
  f41: UnaryFunction<T15, T16>,
  f42: UnaryFunction<T14, T15>,
  f43: UnaryFunction<T13, T14>,
  f44: UnaryFunction<T12, T13>,
  f45: UnaryFunction<T11, T12>,
  f46: UnaryFunction<T10, T11>,
  f47: UnaryFunction<T9, T10>,
  f48: UnaryFunction<T8, T9>,
  f49: UnaryFunction<T7, T8>,
  f50: UnaryFunction<T6, T7>,
  f51: UnaryFunction<T5, T6>,
  f52: UnaryFunction<T4, T5>,
  f53: UnaryFunction<T3, T4>,
  f54: UnaryFunction<T2, T3>,
  f55: UnaryFunction<T1, T2>,
  f56: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  T55,
  T56,
  T57,
  TOut
>(
  f0: UnaryFunction<T57, TOut>,
  f1: UnaryFunction<T56, T57>,
  f2: UnaryFunction<T55, T56>,
  f3: UnaryFunction<T54, T55>,
  f4: UnaryFunction<T53, T54>,
  f5: UnaryFunction<T52, T53>,
  f6: UnaryFunction<T51, T52>,
  f7: UnaryFunction<T50, T51>,
  f8: UnaryFunction<T49, T50>,
  f9: UnaryFunction<T48, T49>,
  f10: UnaryFunction<T47, T48>,
  f11: UnaryFunction<T46, T47>,
  f12: UnaryFunction<T45, T46>,
  f13: UnaryFunction<T44, T45>,
  f14: UnaryFunction<T43, T44>,
  f15: UnaryFunction<T42, T43>,
  f16: UnaryFunction<T41, T42>,
  f17: UnaryFunction<T40, T41>,
  f18: UnaryFunction<T39, T40>,
  f19: UnaryFunction<T38, T39>,
  f20: UnaryFunction<T37, T38>,
  f21: UnaryFunction<T36, T37>,
  f22: UnaryFunction<T35, T36>,
  f23: UnaryFunction<T34, T35>,
  f24: UnaryFunction<T33, T34>,
  f25: UnaryFunction<T32, T33>,
  f26: UnaryFunction<T31, T32>,
  f27: UnaryFunction<T30, T31>,
  f28: UnaryFunction<T29, T30>,
  f29: UnaryFunction<T28, T29>,
  f30: UnaryFunction<T27, T28>,
  f31: UnaryFunction<T26, T27>,
  f32: UnaryFunction<T25, T26>,
  f33: UnaryFunction<T24, T25>,
  f34: UnaryFunction<T23, T24>,
  f35: UnaryFunction<T22, T23>,
  f36: UnaryFunction<T21, T22>,
  f37: UnaryFunction<T20, T21>,
  f38: UnaryFunction<T19, T20>,
  f39: UnaryFunction<T18, T19>,
  f40: UnaryFunction<T17, T18>,
  f41: UnaryFunction<T16, T17>,
  f42: UnaryFunction<T15, T16>,
  f43: UnaryFunction<T14, T15>,
  f44: UnaryFunction<T13, T14>,
  f45: UnaryFunction<T12, T13>,
  f46: UnaryFunction<T11, T12>,
  f47: UnaryFunction<T10, T11>,
  f48: UnaryFunction<T9, T10>,
  f49: UnaryFunction<T8, T9>,
  f50: UnaryFunction<T7, T8>,
  f51: UnaryFunction<T6, T7>,
  f52: UnaryFunction<T5, T6>,
  f53: UnaryFunction<T4, T5>,
  f54: UnaryFunction<T3, T4>,
  f55: UnaryFunction<T2, T3>,
  f56: UnaryFunction<T1, T2>,
  f57: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  T55,
  T56,
  T57,
  T58,
  TOut
>(
  f0: UnaryFunction<T58, TOut>,
  f1: UnaryFunction<T57, T58>,
  f2: UnaryFunction<T56, T57>,
  f3: UnaryFunction<T55, T56>,
  f4: UnaryFunction<T54, T55>,
  f5: UnaryFunction<T53, T54>,
  f6: UnaryFunction<T52, T53>,
  f7: UnaryFunction<T51, T52>,
  f8: UnaryFunction<T50, T51>,
  f9: UnaryFunction<T49, T50>,
  f10: UnaryFunction<T48, T49>,
  f11: UnaryFunction<T47, T48>,
  f12: UnaryFunction<T46, T47>,
  f13: UnaryFunction<T45, T46>,
  f14: UnaryFunction<T44, T45>,
  f15: UnaryFunction<T43, T44>,
  f16: UnaryFunction<T42, T43>,
  f17: UnaryFunction<T41, T42>,
  f18: UnaryFunction<T40, T41>,
  f19: UnaryFunction<T39, T40>,
  f20: UnaryFunction<T38, T39>,
  f21: UnaryFunction<T37, T38>,
  f22: UnaryFunction<T36, T37>,
  f23: UnaryFunction<T35, T36>,
  f24: UnaryFunction<T34, T35>,
  f25: UnaryFunction<T33, T34>,
  f26: UnaryFunction<T32, T33>,
  f27: UnaryFunction<T31, T32>,
  f28: UnaryFunction<T30, T31>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T28, T29>,
  f31: UnaryFunction<T27, T28>,
  f32: UnaryFunction<T26, T27>,
  f33: UnaryFunction<T25, T26>,
  f34: UnaryFunction<T24, T25>,
  f35: UnaryFunction<T23, T24>,
  f36: UnaryFunction<T22, T23>,
  f37: UnaryFunction<T21, T22>,
  f38: UnaryFunction<T20, T21>,
  f39: UnaryFunction<T19, T20>,
  f40: UnaryFunction<T18, T19>,
  f41: UnaryFunction<T17, T18>,
  f42: UnaryFunction<T16, T17>,
  f43: UnaryFunction<T15, T16>,
  f44: UnaryFunction<T14, T15>,
  f45: UnaryFunction<T13, T14>,
  f46: UnaryFunction<T12, T13>,
  f47: UnaryFunction<T11, T12>,
  f48: UnaryFunction<T10, T11>,
  f49: UnaryFunction<T9, T10>,
  f50: UnaryFunction<T8, T9>,
  f51: UnaryFunction<T7, T8>,
  f52: UnaryFunction<T6, T7>,
  f53: UnaryFunction<T5, T6>,
  f54: UnaryFunction<T4, T5>,
  f55: UnaryFunction<T3, T4>,
  f56: UnaryFunction<T2, T3>,
  f57: UnaryFunction<T1, T2>,
  f58: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  T55,
  T56,
  T57,
  T58,
  T59,
  TOut
>(
  f0: UnaryFunction<T59, TOut>,
  f1: UnaryFunction<T58, T59>,
  f2: UnaryFunction<T57, T58>,
  f3: UnaryFunction<T56, T57>,
  f4: UnaryFunction<T55, T56>,
  f5: UnaryFunction<T54, T55>,
  f6: UnaryFunction<T53, T54>,
  f7: UnaryFunction<T52, T53>,
  f8: UnaryFunction<T51, T52>,
  f9: UnaryFunction<T50, T51>,
  f10: UnaryFunction<T49, T50>,
  f11: UnaryFunction<T48, T49>,
  f12: UnaryFunction<T47, T48>,
  f13: UnaryFunction<T46, T47>,
  f14: UnaryFunction<T45, T46>,
  f15: UnaryFunction<T44, T45>,
  f16: UnaryFunction<T43, T44>,
  f17: UnaryFunction<T42, T43>,
  f18: UnaryFunction<T41, T42>,
  f19: UnaryFunction<T40, T41>,
  f20: UnaryFunction<T39, T40>,
  f21: UnaryFunction<T38, T39>,
  f22: UnaryFunction<T37, T38>,
  f23: UnaryFunction<T36, T37>,
  f24: UnaryFunction<T35, T36>,
  f25: UnaryFunction<T34, T35>,
  f26: UnaryFunction<T33, T34>,
  f27: UnaryFunction<T32, T33>,
  f28: UnaryFunction<T31, T32>,
  f29: UnaryFunction<T30, T31>,
  f30: UnaryFunction<T29, T30>,
  f31: UnaryFunction<T28, T29>,
  f32: UnaryFunction<T27, T28>,
  f33: UnaryFunction<T26, T27>,
  f34: UnaryFunction<T25, T26>,
  f35: UnaryFunction<T24, T25>,
  f36: UnaryFunction<T23, T24>,
  f37: UnaryFunction<T22, T23>,
  f38: UnaryFunction<T21, T22>,
  f39: UnaryFunction<T20, T21>,
  f40: UnaryFunction<T19, T20>,
  f41: UnaryFunction<T18, T19>,
  f42: UnaryFunction<T17, T18>,
  f43: UnaryFunction<T16, T17>,
  f44: UnaryFunction<T15, T16>,
  f45: UnaryFunction<T14, T15>,
  f46: UnaryFunction<T13, T14>,
  f47: UnaryFunction<T12, T13>,
  f48: UnaryFunction<T11, T12>,
  f49: UnaryFunction<T10, T11>,
  f50: UnaryFunction<T9, T10>,
  f51: UnaryFunction<T8, T9>,
  f52: UnaryFunction<T7, T8>,
  f53: UnaryFunction<T6, T7>,
  f54: UnaryFunction<T5, T6>,
  f55: UnaryFunction<T4, T5>,
  f56: UnaryFunction<T3, T4>,
  f57: UnaryFunction<T2, T3>,
  f58: UnaryFunction<T1, T2>,
  f59: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  T55,
  T56,
  T57,
  T58,
  T59,
  T60,
  TOut
>(
  f0: UnaryFunction<T60, TOut>,
  f1: UnaryFunction<T59, T60>,
  f2: UnaryFunction<T58, T59>,
  f3: UnaryFunction<T57, T58>,
  f4: UnaryFunction<T56, T57>,
  f5: UnaryFunction<T55, T56>,
  f6: UnaryFunction<T54, T55>,
  f7: UnaryFunction<T53, T54>,
  f8: UnaryFunction<T52, T53>,
  f9: UnaryFunction<T51, T52>,
  f10: UnaryFunction<T50, T51>,
  f11: UnaryFunction<T49, T50>,
  f12: UnaryFunction<T48, T49>,
  f13: UnaryFunction<T47, T48>,
  f14: UnaryFunction<T46, T47>,
  f15: UnaryFunction<T45, T46>,
  f16: UnaryFunction<T44, T45>,
  f17: UnaryFunction<T43, T44>,
  f18: UnaryFunction<T42, T43>,
  f19: UnaryFunction<T41, T42>,
  f20: UnaryFunction<T40, T41>,
  f21: UnaryFunction<T39, T40>,
  f22: UnaryFunction<T38, T39>,
  f23: UnaryFunction<T37, T38>,
  f24: UnaryFunction<T36, T37>,
  f25: UnaryFunction<T35, T36>,
  f26: UnaryFunction<T34, T35>,
  f27: UnaryFunction<T33, T34>,
  f28: UnaryFunction<T32, T33>,
  f29: UnaryFunction<T31, T32>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T29, T30>,
  f32: UnaryFunction<T28, T29>,
  f33: UnaryFunction<T27, T28>,
  f34: UnaryFunction<T26, T27>,
  f35: UnaryFunction<T25, T26>,
  f36: UnaryFunction<T24, T25>,
  f37: UnaryFunction<T23, T24>,
  f38: UnaryFunction<T22, T23>,
  f39: UnaryFunction<T21, T22>,
  f40: UnaryFunction<T20, T21>,
  f41: UnaryFunction<T19, T20>,
  f42: UnaryFunction<T18, T19>,
  f43: UnaryFunction<T17, T18>,
  f44: UnaryFunction<T16, T17>,
  f45: UnaryFunction<T15, T16>,
  f46: UnaryFunction<T14, T15>,
  f47: UnaryFunction<T13, T14>,
  f48: UnaryFunction<T12, T13>,
  f49: UnaryFunction<T11, T12>,
  f50: UnaryFunction<T10, T11>,
  f51: UnaryFunction<T9, T10>,
  f52: UnaryFunction<T8, T9>,
  f53: UnaryFunction<T7, T8>,
  f54: UnaryFunction<T6, T7>,
  f55: UnaryFunction<T5, T6>,
  f56: UnaryFunction<T4, T5>,
  f57: UnaryFunction<T3, T4>,
  f58: UnaryFunction<T2, T3>,
  f59: UnaryFunction<T1, T2>,
  f60: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  T55,
  T56,
  T57,
  T58,
  T59,
  T60,
  T61,
  TOut
>(
  f0: UnaryFunction<T61, TOut>,
  f1: UnaryFunction<T60, T61>,
  f2: UnaryFunction<T59, T60>,
  f3: UnaryFunction<T58, T59>,
  f4: UnaryFunction<T57, T58>,
  f5: UnaryFunction<T56, T57>,
  f6: UnaryFunction<T55, T56>,
  f7: UnaryFunction<T54, T55>,
  f8: UnaryFunction<T53, T54>,
  f9: UnaryFunction<T52, T53>,
  f10: UnaryFunction<T51, T52>,
  f11: UnaryFunction<T50, T51>,
  f12: UnaryFunction<T49, T50>,
  f13: UnaryFunction<T48, T49>,
  f14: UnaryFunction<T47, T48>,
  f15: UnaryFunction<T46, T47>,
  f16: UnaryFunction<T45, T46>,
  f17: UnaryFunction<T44, T45>,
  f18: UnaryFunction<T43, T44>,
  f19: UnaryFunction<T42, T43>,
  f20: UnaryFunction<T41, T42>,
  f21: UnaryFunction<T40, T41>,
  f22: UnaryFunction<T39, T40>,
  f23: UnaryFunction<T38, T39>,
  f24: UnaryFunction<T37, T38>,
  f25: UnaryFunction<T36, T37>,
  f26: UnaryFunction<T35, T36>,
  f27: UnaryFunction<T34, T35>,
  f28: UnaryFunction<T33, T34>,
  f29: UnaryFunction<T32, T33>,
  f30: UnaryFunction<T31, T32>,
  f31: UnaryFunction<T30, T31>,
  f32: UnaryFunction<T29, T30>,
  f33: UnaryFunction<T28, T29>,
  f34: UnaryFunction<T27, T28>,
  f35: UnaryFunction<T26, T27>,
  f36: UnaryFunction<T25, T26>,
  f37: UnaryFunction<T24, T25>,
  f38: UnaryFunction<T23, T24>,
  f39: UnaryFunction<T22, T23>,
  f40: UnaryFunction<T21, T22>,
  f41: UnaryFunction<T20, T21>,
  f42: UnaryFunction<T19, T20>,
  f43: UnaryFunction<T18, T19>,
  f44: UnaryFunction<T17, T18>,
  f45: UnaryFunction<T16, T17>,
  f46: UnaryFunction<T15, T16>,
  f47: UnaryFunction<T14, T15>,
  f48: UnaryFunction<T13, T14>,
  f49: UnaryFunction<T12, T13>,
  f50: UnaryFunction<T11, T12>,
  f51: UnaryFunction<T10, T11>,
  f52: UnaryFunction<T9, T10>,
  f53: UnaryFunction<T8, T9>,
  f54: UnaryFunction<T7, T8>,
  f55: UnaryFunction<T6, T7>,
  f56: UnaryFunction<T5, T6>,
  f57: UnaryFunction<T4, T5>,
  f58: UnaryFunction<T3, T4>,
  f59: UnaryFunction<T2, T3>,
  f60: UnaryFunction<T1, T2>,
  f61: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  T55,
  T56,
  T57,
  T58,
  T59,
  T60,
  T61,
  T62,
  TOut
>(
  f0: UnaryFunction<T62, TOut>,
  f1: UnaryFunction<T61, T62>,
  f2: UnaryFunction<T60, T61>,
  f3: UnaryFunction<T59, T60>,
  f4: UnaryFunction<T58, T59>,
  f5: UnaryFunction<T57, T58>,
  f6: UnaryFunction<T56, T57>,
  f7: UnaryFunction<T55, T56>,
  f8: UnaryFunction<T54, T55>,
  f9: UnaryFunction<T53, T54>,
  f10: UnaryFunction<T52, T53>,
  f11: UnaryFunction<T51, T52>,
  f12: UnaryFunction<T50, T51>,
  f13: UnaryFunction<T49, T50>,
  f14: UnaryFunction<T48, T49>,
  f15: UnaryFunction<T47, T48>,
  f16: UnaryFunction<T46, T47>,
  f17: UnaryFunction<T45, T46>,
  f18: UnaryFunction<T44, T45>,
  f19: UnaryFunction<T43, T44>,
  f20: UnaryFunction<T42, T43>,
  f21: UnaryFunction<T41, T42>,
  f22: UnaryFunction<T40, T41>,
  f23: UnaryFunction<T39, T40>,
  f24: UnaryFunction<T38, T39>,
  f25: UnaryFunction<T37, T38>,
  f26: UnaryFunction<T36, T37>,
  f27: UnaryFunction<T35, T36>,
  f28: UnaryFunction<T34, T35>,
  f29: UnaryFunction<T33, T34>,
  f30: UnaryFunction<T32, T33>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T30, T31>,
  f33: UnaryFunction<T29, T30>,
  f34: UnaryFunction<T28, T29>,
  f35: UnaryFunction<T27, T28>,
  f36: UnaryFunction<T26, T27>,
  f37: UnaryFunction<T25, T26>,
  f38: UnaryFunction<T24, T25>,
  f39: UnaryFunction<T23, T24>,
  f40: UnaryFunction<T22, T23>,
  f41: UnaryFunction<T21, T22>,
  f42: UnaryFunction<T20, T21>,
  f43: UnaryFunction<T19, T20>,
  f44: UnaryFunction<T18, T19>,
  f45: UnaryFunction<T17, T18>,
  f46: UnaryFunction<T16, T17>,
  f47: UnaryFunction<T15, T16>,
  f48: UnaryFunction<T14, T15>,
  f49: UnaryFunction<T13, T14>,
  f50: UnaryFunction<T12, T13>,
  f51: UnaryFunction<T11, T12>,
  f52: UnaryFunction<T10, T11>,
  f53: UnaryFunction<T9, T10>,
  f54: UnaryFunction<T8, T9>,
  f55: UnaryFunction<T7, T8>,
  f56: UnaryFunction<T6, T7>,
  f57: UnaryFunction<T5, T6>,
  f58: UnaryFunction<T4, T5>,
  f59: UnaryFunction<T3, T4>,
  f60: UnaryFunction<T2, T3>,
  f61: UnaryFunction<T1, T2>,
  f62: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<
  TIn extends any[],
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  T55,
  T56,
  T57,
  T58,
  T59,
  T60,
  T61,
  T62,
  T63,
  TOut
>(
  f0: UnaryFunction<T63, TOut>,
  f1: UnaryFunction<T62, T63>,
  f2: UnaryFunction<T61, T62>,
  f3: UnaryFunction<T60, T61>,
  f4: UnaryFunction<T59, T60>,
  f5: UnaryFunction<T58, T59>,
  f6: UnaryFunction<T57, T58>,
  f7: UnaryFunction<T56, T57>,
  f8: UnaryFunction<T55, T56>,
  f9: UnaryFunction<T54, T55>,
  f10: UnaryFunction<T53, T54>,
  f11: UnaryFunction<T52, T53>,
  f12: UnaryFunction<T51, T52>,
  f13: UnaryFunction<T50, T51>,
  f14: UnaryFunction<T49, T50>,
  f15: UnaryFunction<T48, T49>,
  f16: UnaryFunction<T47, T48>,
  f17: UnaryFunction<T46, T47>,
  f18: UnaryFunction<T45, T46>,
  f19: UnaryFunction<T44, T45>,
  f20: UnaryFunction<T43, T44>,
  f21: UnaryFunction<T42, T43>,
  f22: UnaryFunction<T41, T42>,
  f23: UnaryFunction<T40, T41>,
  f24: UnaryFunction<T39, T40>,
  f25: UnaryFunction<T38, T39>,
  f26: UnaryFunction<T37, T38>,
  f27: UnaryFunction<T36, T37>,
  f28: UnaryFunction<T35, T36>,
  f29: UnaryFunction<T34, T35>,
  f30: UnaryFunction<T33, T34>,
  f31: UnaryFunction<T32, T33>,
  f32: UnaryFunction<T31, T32>,
  f33: UnaryFunction<T30, T31>,
  f34: UnaryFunction<T29, T30>,
  f35: UnaryFunction<T28, T29>,
  f36: UnaryFunction<T27, T28>,
  f37: UnaryFunction<T26, T27>,
  f38: UnaryFunction<T25, T26>,
  f39: UnaryFunction<T24, T25>,
  f40: UnaryFunction<T23, T24>,
  f41: UnaryFunction<T22, T23>,
  f42: UnaryFunction<T21, T22>,
  f43: UnaryFunction<T20, T21>,
  f44: UnaryFunction<T19, T20>,
  f45: UnaryFunction<T18, T19>,
  f46: UnaryFunction<T17, T18>,
  f47: UnaryFunction<T16, T17>,
  f48: UnaryFunction<T15, T16>,
  f49: UnaryFunction<T14, T15>,
  f50: UnaryFunction<T13, T14>,
  f51: UnaryFunction<T12, T13>,
  f52: UnaryFunction<T11, T12>,
  f53: UnaryFunction<T10, T11>,
  f54: UnaryFunction<T9, T10>,
  f55: UnaryFunction<T8, T9>,
  f56: UnaryFunction<T7, T8>,
  f57: UnaryFunction<T6, T7>,
  f58: UnaryFunction<T5, T6>,
  f59: UnaryFunction<T4, T5>,
  f60: UnaryFunction<T3, T4>,
  f61: UnaryFunction<T2, T3>,
  f62: UnaryFunction<T1, T2>,
  f63: Func<TIn, T1>
): Func<TIn, TOut>
export function compose<TIn extends any[], TOut>(
  ...args: [...operations: UnaryFunction<any, any>[], o1: Func<TIn, any>]
): Func<TIn, TOut> {
  const o1 = args[args.length - 1] as Func<TIn, any>
  const operations = args.slice(0, -1).reverse() as UnaryFunction<any, any>[]
  return pipeImpl(o1, ...operations)
}

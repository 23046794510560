import { Func } from './types/Func'
import { UnaryFunction } from './types/UnaryFunction'
import { pipeImpl } from './pipeImpl'
import { applyArgs } from './applyArgs'

/**
 * `pipeInto(src, f1, f2)` is shorthand for `applyArgs(src).to(pipe(f1, f2))`
 */
export function pipeInto<TIn, TOut>(src: TIn, f0: Func<[TIn], TOut>): TOut
export function pipeInto<TIn, T1, TOut>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, TOut>
): TOut
export function pipeInto<TIn, T1, T2, TOut>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, TOut>
): TOut
export function pipeInto<TIn, T1, T2, T3, TOut>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, TOut>
): TOut
export function pipeInto<TIn, T1, T2, T3, T4, TOut>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, TOut>
): TOut
export function pipeInto<TIn, T1, T2, T3, T4, T5, TOut>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, TOut>
): TOut
export function pipeInto<TIn, T1, T2, T3, T4, T5, T6, TOut>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, TOut>
): TOut
export function pipeInto<TIn, T1, T2, T3, T4, T5, T6, T7, TOut>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, TOut>
): TOut
export function pipeInto<TIn, T1, T2, T3, T4, T5, T6, T7, T8, TOut>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, TOut>
): TOut
export function pipeInto<TIn, T1, T2, T3, T4, T5, T6, T7, T8, T9, TOut>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, TOut>
): TOut
export function pipeInto<TIn, T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, TOut>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, TOut>
): TOut
export function pipeInto<TIn, T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, T11, TOut>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, TOut>
): TOut
export function pipeInto<TIn, T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, T11, T12, TOut>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, TOut>
): TOut
export function pipeInto<TIn, T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, T11, T12, T13, TOut>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, TOut>
): TOut
export function pipeInto<TIn, T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, T11, T12, T13, T14, TOut>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, T46>,
  f46: UnaryFunction<T46, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, T46>,
  f46: UnaryFunction<T46, T47>,
  f47: UnaryFunction<T47, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, T46>,
  f46: UnaryFunction<T46, T47>,
  f47: UnaryFunction<T47, T48>,
  f48: UnaryFunction<T48, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, T46>,
  f46: UnaryFunction<T46, T47>,
  f47: UnaryFunction<T47, T48>,
  f48: UnaryFunction<T48, T49>,
  f49: UnaryFunction<T49, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, T46>,
  f46: UnaryFunction<T46, T47>,
  f47: UnaryFunction<T47, T48>,
  f48: UnaryFunction<T48, T49>,
  f49: UnaryFunction<T49, T50>,
  f50: UnaryFunction<T50, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, T46>,
  f46: UnaryFunction<T46, T47>,
  f47: UnaryFunction<T47, T48>,
  f48: UnaryFunction<T48, T49>,
  f49: UnaryFunction<T49, T50>,
  f50: UnaryFunction<T50, T51>,
  f51: UnaryFunction<T51, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, T46>,
  f46: UnaryFunction<T46, T47>,
  f47: UnaryFunction<T47, T48>,
  f48: UnaryFunction<T48, T49>,
  f49: UnaryFunction<T49, T50>,
  f50: UnaryFunction<T50, T51>,
  f51: UnaryFunction<T51, T52>,
  f52: UnaryFunction<T52, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, T46>,
  f46: UnaryFunction<T46, T47>,
  f47: UnaryFunction<T47, T48>,
  f48: UnaryFunction<T48, T49>,
  f49: UnaryFunction<T49, T50>,
  f50: UnaryFunction<T50, T51>,
  f51: UnaryFunction<T51, T52>,
  f52: UnaryFunction<T52, T53>,
  f53: UnaryFunction<T53, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, T46>,
  f46: UnaryFunction<T46, T47>,
  f47: UnaryFunction<T47, T48>,
  f48: UnaryFunction<T48, T49>,
  f49: UnaryFunction<T49, T50>,
  f50: UnaryFunction<T50, T51>,
  f51: UnaryFunction<T51, T52>,
  f52: UnaryFunction<T52, T53>,
  f53: UnaryFunction<T53, T54>,
  f54: UnaryFunction<T54, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  T55,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, T46>,
  f46: UnaryFunction<T46, T47>,
  f47: UnaryFunction<T47, T48>,
  f48: UnaryFunction<T48, T49>,
  f49: UnaryFunction<T49, T50>,
  f50: UnaryFunction<T50, T51>,
  f51: UnaryFunction<T51, T52>,
  f52: UnaryFunction<T52, T53>,
  f53: UnaryFunction<T53, T54>,
  f54: UnaryFunction<T54, T55>,
  f55: UnaryFunction<T55, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  T55,
  T56,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, T46>,
  f46: UnaryFunction<T46, T47>,
  f47: UnaryFunction<T47, T48>,
  f48: UnaryFunction<T48, T49>,
  f49: UnaryFunction<T49, T50>,
  f50: UnaryFunction<T50, T51>,
  f51: UnaryFunction<T51, T52>,
  f52: UnaryFunction<T52, T53>,
  f53: UnaryFunction<T53, T54>,
  f54: UnaryFunction<T54, T55>,
  f55: UnaryFunction<T55, T56>,
  f56: UnaryFunction<T56, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  T55,
  T56,
  T57,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, T46>,
  f46: UnaryFunction<T46, T47>,
  f47: UnaryFunction<T47, T48>,
  f48: UnaryFunction<T48, T49>,
  f49: UnaryFunction<T49, T50>,
  f50: UnaryFunction<T50, T51>,
  f51: UnaryFunction<T51, T52>,
  f52: UnaryFunction<T52, T53>,
  f53: UnaryFunction<T53, T54>,
  f54: UnaryFunction<T54, T55>,
  f55: UnaryFunction<T55, T56>,
  f56: UnaryFunction<T56, T57>,
  f57: UnaryFunction<T57, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  T55,
  T56,
  T57,
  T58,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, T46>,
  f46: UnaryFunction<T46, T47>,
  f47: UnaryFunction<T47, T48>,
  f48: UnaryFunction<T48, T49>,
  f49: UnaryFunction<T49, T50>,
  f50: UnaryFunction<T50, T51>,
  f51: UnaryFunction<T51, T52>,
  f52: UnaryFunction<T52, T53>,
  f53: UnaryFunction<T53, T54>,
  f54: UnaryFunction<T54, T55>,
  f55: UnaryFunction<T55, T56>,
  f56: UnaryFunction<T56, T57>,
  f57: UnaryFunction<T57, T58>,
  f58: UnaryFunction<T58, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  T55,
  T56,
  T57,
  T58,
  T59,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, T46>,
  f46: UnaryFunction<T46, T47>,
  f47: UnaryFunction<T47, T48>,
  f48: UnaryFunction<T48, T49>,
  f49: UnaryFunction<T49, T50>,
  f50: UnaryFunction<T50, T51>,
  f51: UnaryFunction<T51, T52>,
  f52: UnaryFunction<T52, T53>,
  f53: UnaryFunction<T53, T54>,
  f54: UnaryFunction<T54, T55>,
  f55: UnaryFunction<T55, T56>,
  f56: UnaryFunction<T56, T57>,
  f57: UnaryFunction<T57, T58>,
  f58: UnaryFunction<T58, T59>,
  f59: UnaryFunction<T59, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  T55,
  T56,
  T57,
  T58,
  T59,
  T60,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, T46>,
  f46: UnaryFunction<T46, T47>,
  f47: UnaryFunction<T47, T48>,
  f48: UnaryFunction<T48, T49>,
  f49: UnaryFunction<T49, T50>,
  f50: UnaryFunction<T50, T51>,
  f51: UnaryFunction<T51, T52>,
  f52: UnaryFunction<T52, T53>,
  f53: UnaryFunction<T53, T54>,
  f54: UnaryFunction<T54, T55>,
  f55: UnaryFunction<T55, T56>,
  f56: UnaryFunction<T56, T57>,
  f57: UnaryFunction<T57, T58>,
  f58: UnaryFunction<T58, T59>,
  f59: UnaryFunction<T59, T60>,
  f60: UnaryFunction<T60, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  T55,
  T56,
  T57,
  T58,
  T59,
  T60,
  T61,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, T46>,
  f46: UnaryFunction<T46, T47>,
  f47: UnaryFunction<T47, T48>,
  f48: UnaryFunction<T48, T49>,
  f49: UnaryFunction<T49, T50>,
  f50: UnaryFunction<T50, T51>,
  f51: UnaryFunction<T51, T52>,
  f52: UnaryFunction<T52, T53>,
  f53: UnaryFunction<T53, T54>,
  f54: UnaryFunction<T54, T55>,
  f55: UnaryFunction<T55, T56>,
  f56: UnaryFunction<T56, T57>,
  f57: UnaryFunction<T57, T58>,
  f58: UnaryFunction<T58, T59>,
  f59: UnaryFunction<T59, T60>,
  f60: UnaryFunction<T60, T61>,
  f61: UnaryFunction<T61, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  T55,
  T56,
  T57,
  T58,
  T59,
  T60,
  T61,
  T62,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, T46>,
  f46: UnaryFunction<T46, T47>,
  f47: UnaryFunction<T47, T48>,
  f48: UnaryFunction<T48, T49>,
  f49: UnaryFunction<T49, T50>,
  f50: UnaryFunction<T50, T51>,
  f51: UnaryFunction<T51, T52>,
  f52: UnaryFunction<T52, T53>,
  f53: UnaryFunction<T53, T54>,
  f54: UnaryFunction<T54, T55>,
  f55: UnaryFunction<T55, T56>,
  f56: UnaryFunction<T56, T57>,
  f57: UnaryFunction<T57, T58>,
  f58: UnaryFunction<T58, T59>,
  f59: UnaryFunction<T59, T60>,
  f60: UnaryFunction<T60, T61>,
  f61: UnaryFunction<T61, T62>,
  f62: UnaryFunction<T62, TOut>
): TOut
export function pipeInto<
  TIn,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  T9,
  T10,
  T11,
  T12,
  T13,
  T14,
  T15,
  T16,
  T17,
  T18,
  T19,
  T20,
  T21,
  T22,
  T23,
  T24,
  T25,
  T26,
  T27,
  T28,
  T29,
  T30,
  T31,
  T32,
  T33,
  T34,
  T35,
  T36,
  T37,
  T38,
  T39,
  T40,
  T41,
  T42,
  T43,
  T44,
  T45,
  T46,
  T47,
  T48,
  T49,
  T50,
  T51,
  T52,
  T53,
  T54,
  T55,
  T56,
  T57,
  T58,
  T59,
  T60,
  T61,
  T62,
  T63,
  TOut
>(
  src: TIn,
  f0: Func<[TIn], T1>,
  f1: UnaryFunction<T1, T2>,
  f2: UnaryFunction<T2, T3>,
  f3: UnaryFunction<T3, T4>,
  f4: UnaryFunction<T4, T5>,
  f5: UnaryFunction<T5, T6>,
  f6: UnaryFunction<T6, T7>,
  f7: UnaryFunction<T7, T8>,
  f8: UnaryFunction<T8, T9>,
  f9: UnaryFunction<T9, T10>,
  f10: UnaryFunction<T10, T11>,
  f11: UnaryFunction<T11, T12>,
  f12: UnaryFunction<T12, T13>,
  f13: UnaryFunction<T13, T14>,
  f14: UnaryFunction<T14, T15>,
  f15: UnaryFunction<T15, T16>,
  f16: UnaryFunction<T16, T17>,
  f17: UnaryFunction<T17, T18>,
  f18: UnaryFunction<T18, T19>,
  f19: UnaryFunction<T19, T20>,
  f20: UnaryFunction<T20, T21>,
  f21: UnaryFunction<T21, T22>,
  f22: UnaryFunction<T22, T23>,
  f23: UnaryFunction<T23, T24>,
  f24: UnaryFunction<T24, T25>,
  f25: UnaryFunction<T25, T26>,
  f26: UnaryFunction<T26, T27>,
  f27: UnaryFunction<T27, T28>,
  f28: UnaryFunction<T28, T29>,
  f29: UnaryFunction<T29, T30>,
  f30: UnaryFunction<T30, T31>,
  f31: UnaryFunction<T31, T32>,
  f32: UnaryFunction<T32, T33>,
  f33: UnaryFunction<T33, T34>,
  f34: UnaryFunction<T34, T35>,
  f35: UnaryFunction<T35, T36>,
  f36: UnaryFunction<T36, T37>,
  f37: UnaryFunction<T37, T38>,
  f38: UnaryFunction<T38, T39>,
  f39: UnaryFunction<T39, T40>,
  f40: UnaryFunction<T40, T41>,
  f41: UnaryFunction<T41, T42>,
  f42: UnaryFunction<T42, T43>,
  f43: UnaryFunction<T43, T44>,
  f44: UnaryFunction<T44, T45>,
  f45: UnaryFunction<T45, T46>,
  f46: UnaryFunction<T46, T47>,
  f47: UnaryFunction<T47, T48>,
  f48: UnaryFunction<T48, T49>,
  f49: UnaryFunction<T49, T50>,
  f50: UnaryFunction<T50, T51>,
  f51: UnaryFunction<T51, T52>,
  f52: UnaryFunction<T52, T53>,
  f53: UnaryFunction<T53, T54>,
  f54: UnaryFunction<T54, T55>,
  f55: UnaryFunction<T55, T56>,
  f56: UnaryFunction<T56, T57>,
  f57: UnaryFunction<T57, T58>,
  f58: UnaryFunction<T58, T59>,
  f59: UnaryFunction<T59, T60>,
  f60: UnaryFunction<T60, T61>,
  f61: UnaryFunction<T61, T62>,
  f62: UnaryFunction<T62, T63>,
  f63: UnaryFunction<T63, TOut>
): TOut
export function pipeInto<TIn, TOut>(
  src: TIn,
  o1: Func<[TIn], any>,
  ...operations: UnaryFunction<any, any>[]
): TOut {
  return applyArgs(src).to(pipeImpl(o1, ...operations))
}
